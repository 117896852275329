import React, { useState, useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const CurrencyRatesPopover = ({ usedCurrencyRates = {}, onCurrencyChanged }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [rates, setRates] = useState({
        usd: usedCurrencyRates.usd || 1,
        eur: usedCurrencyRates.eur || 1,
        gbp: usedCurrencyRates.gbp || 1
    });

    // Update rates when usedCurrencyRates changes
    // useEffect(() => {
    //     setRates({
    //         usd: usedCurrencyRates.usd || 1,
    //         eur: usedCurrencyRates.eur || 1,
    //         gbp: usedCurrencyRates.gbp || 1
    //     });
    // }, [usedCurrencyRates]);


    useEffect(() => {
        if (usedCurrencyRates && JSON.stringify(usedCurrencyRates) !== JSON.stringify(rates)) {
            setRates(usedCurrencyRates);
        }
    }, [usedCurrencyRates, rates]); 

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSave = () => {
        onCurrencyChanged(rates);
        handleClose();
    };

    const handleChange = (currency) => (event) => {
        setRates({
            ...rates,
            [currency]: parseFloat(event.target.value) || 0
        });
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Button  size="small" onClick={handleClick}>
                Currency Rates
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: 20 }}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <TextField
                                label="USD Rate"
                                type="number"
                                value={rates.usd}
                                onChange={handleChange('usd')}
                                inputProps={{ step: "0.01" }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="EUR Rate"
                                type="number"
                                value={rates.eur}
                                onChange={handleChange('eur')}
                                inputProps={{ step: "0.01" }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="GBP Rate"
                                type="number"
                                value={rates.gbp}
                                onChange={handleChange('gbp')}
                                inputProps={{ step: "0.01" }}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleSave}>
                                Save Rates
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Popover>
        </>
    );
};

export default CurrencyRatesPopover; 