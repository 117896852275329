import moment from 'moment';
const DB_NAME = 'payrollDB';
const STORE_NAME = 'checkpoints';
const DB_VERSION = 1;


class IndexedDBService {
    constructor() {
        this.db = null;
        this.initDB();
    }

    initDB() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(DB_NAME, DB_VERSION);

            request.onerror = () => {
                reject(request.error);
            };

            request.onsuccess = (event) => {
                this.db = event.target.result;
                resolve(this.db);
            };

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(STORE_NAME)) {
                    db.createObjectStore(STORE_NAME, { keyPath: 'checkpointId' });
                }
            };
        });
    }

    async ensureDB() {
        if (!this.db) {
            await this.initDB();
        }
        return this.db;
    }

    serializeCheckpoint(checkpoint) {
        // Create a deep copy and convert moment objects to ISO strings
        return {
            ...checkpoint,
            payrolls: checkpoint.payrolls.map(p => ({
                ...p,
                // Convert any moment dates to strings
                payrollDate: p.payrollDate ? moment(p.payrollDate).toISOString() : null,
                periodStart: p.periodStart ? moment(p.periodStart).toISOString() : null,
                periodEnd: p.periodEnd ? moment(p.periodEnd).toISOString() : null,
            })),
            // Convert top-level dates
            payrollDate: checkpoint.payrollDate ? moment(checkpoint.payrollDate).toISOString() : null,
            periodStart: checkpoint.periodStart ? moment(checkpoint.periodStart).toISOString() : null,
            periodEnd: checkpoint.periodEnd ? moment(checkpoint.periodEnd).toISOString() : null,
            savedOn: checkpoint.savedOn ? moment(checkpoint.savedOn).toISOString() : null,
        };
    }

    deserializeCheckpoint(checkpoint) {
        // Convert ISO strings back to moment objects
        return {
            ...checkpoint,
            payrolls: checkpoint.payrolls.map(p => ({
                ...p,
                payrollDate: p.payrollDate ? moment(p.payrollDate) : null,
                periodStart: p.periodStart ? moment(p.periodStart) : null,
                periodEnd: p.periodEnd ? moment(p.periodEnd) : null,
            })),
            payrollDate: checkpoint.payrollDate ? moment(checkpoint.payrollDate) : null,
            periodStart: checkpoint.periodStart ? moment(checkpoint.periodStart) : null,
            periodEnd: checkpoint.periodEnd ? moment(checkpoint.periodEnd) : null,
            savedOn: checkpoint.savedOn ? moment(checkpoint.savedOn) : null,
        };
    }

    async saveCheckpoints(checkpoints) {
        const db = await this.ensureDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction([STORE_NAME], 'readwrite');
            const store = transaction.objectStore(STORE_NAME);

            // Clear existing checkpoints
            store.clear();

            // Serialize and add all checkpoints
            checkpoints.forEach(checkpoint => {
                try {
                    const serializedCheckpoint = this.serializeCheckpoint(checkpoint);
                    store.add(serializedCheckpoint);
                } catch (error) {
                    console.error('Error serializing checkpoint:', error);
                    reject(error);
                }
            });

            transaction.oncomplete = () => resolve();
            transaction.onerror = () => reject(transaction.error);
        });
    }

    async getCheckpoints() {
        const db = await this.ensureDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction([STORE_NAME], 'readonly');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.getAll();

            request.onsuccess = () => {
                // Deserialize all checkpoints before returning
                const checkpoints = request.result.map(checkpoint => 
                    this.deserializeCheckpoint(checkpoint)
                );
                resolve(checkpoints);
            };
            request.onerror = () => reject(request.error);
        });
    }
}

export const indexedDBService = new IndexedDBService(); 