import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import BonusItem from "./bonusItem";
// import {Button} from 'react-admin'



//const Bonuses = [{rate:12, name:'After Work'},{rate:12,name:'Sunday'},{rate:12,name:'HollyDay'}]


export default function AddBonusDialog(props) {


    // const {bonuses} = props
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [bonuses, setBonuses] = React.useState(props.bonuses);
    // const [oTType, setOTType] = React.useState(Bonuses[0]);
    // const [hrs, setHrs] = React.useState(0);
    //  console.log(bonuses)
    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };
    const handleAdd = () => {

        // console.log(bonuses)
        props.onAdd(bonuses)
        setDialogOpen(false);
    };
    // console.log('ottype',oTType)

    const handleBonusesChange = index => ot => {
        // console.log({ot})
        const newRoles = bonuses.slice(0); // Create a shallow copy of the roles
        newRoles[index] = ot; // Set the new value
        setBonuses(newRoles)
    }

    const handleRemove = index => () => {
        const newRoles = bonuses.slice(0); // Create a shallow copy of the roles
        newRoles.splice(index,1)
        setBonuses(newRoles)
    }
    return (
        <div  style={{marginTop:-20}}>

            <Button variant='text' color="primary" onClick={handleClickOpen}>
                + Extra Earning
            </Button>
            <Dialog maxWidth='md' open={dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">Extra Earnings</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>*/}
                    {/*To subscribe to this website, please enter your email address here. We will send updates*/}
                    {/*occasionally.*/}
                    {/*</DialogContentText>*/}

                    {bonuses.map((ot,index) =>
                        <BonusItem payroll={props.payroll}  key={index} value={ot} onRemove={handleRemove(index)}  onChange={handleBonusesChange(index)} usedCurrencyRates={props.usedCurrencyRates} />
                    )}

                    <Button onClick={()=>setBonuses([...bonuses,{taxOption:'Taxable'}])} color="primary">
                        Add
                    </Button>




                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAdd} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
