const initialState = {
    isImporting: false,
    currentOperation: '',
    progress: 0,
    total: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'IMPORT_PROGRESS_START':
            return {
                ...state,
                isImporting: true,
                total: action.payload.total,
                progress: 0,
                currentOperation: action.payload.operation
            };
        case 'IMPORT_PROGRESS_UPDATE':
            return {
                ...state,
                progress: action.payload.progress,
                currentOperation: action.payload.operation
            };
        case 'IMPORT_PROGRESS_END':
            return initialState;
        default:
            return state;
    }
}; 