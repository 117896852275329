// in src/posts.js
import React, {Fragment} from 'react';
import {
    AutocompleteInput, Create,
    Datagrid, Edit,
    EditButton, FormDataConsumer,
    List,
    ReferenceField,
    ReferenceInput, required, SelectInput,
    Show, SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    NumberField,
    ShowButton,
    NumberInput
} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import {useForm} from "react-final-form";
import {parse} from "query-string";
import DefaultListActions, {Empty} from "./common";


export const RegionList = props => (
    <List   exporter={exporterCommon(props.resource)} actions={<DefaultListActions/>} {...props} empty={<Empty />} >
        <Datagrid rowClick='show' >
            <TextField source="name" />
            <TextField source="code" />
            {/* <TextField source="allowanceValueType" /> */}
            <NumberField source="allowanceValue" />
            {/*<TextField source="title" />*/}
            {/*<ShowButton />*/}
            <EditButton />
        </Datagrid>

    </List>
);


export const RegionShow = props => (
    <Show  {...props}>
        <SimpleShowLayout>
            <TextField source='name' />
            <TextField source='code' />
            <TextField source='allowanceValueType' />
            <NumberField source='allowanceValue' />
        

        </SimpleShowLayout>
    </Show>



);


export const RegionEdit = props => {

    return <Edit {...props}>
        <SimpleForm redirect='list'  >
            <TextInput source='name' />
            <TextInput source='code' />
            <SelectInput   source="allowanceValueType" choices={[
                {id: 'Absolute', name: 'Absolute'},
                {id: 'Percentage', name: 'Percentage'},

            ]}/>
            <NumberInput source='allowanceValue' />
           
        </SimpleForm>
    </Edit>

};


export const RegionCreate = props => {

    return <Create {...props}  >

        <SimpleForm redirect='list'   >
        <TextInput source='name' />
            <TextInput source='code' />
            <SelectInput   source="allowanceValueType" choices={[
                {id: 'Absolute', name: 'Absolute'},
                {id: 'Percentage', name: 'Percentage'},

            ]}/>
            <NumberInput source='allowanceValue' />
           
        </SimpleForm>

    </Create>
};
