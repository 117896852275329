export const earningTypes =[
    { id: 'commission', name: 'Commission', taxOption:'Taxable', value:0 },
    { id: 'bonus', name: 'Bonus', taxOption:'NonTaxable',value:0 },
    // { id: 'annualBonus', name: 'Annual Bonus', taxOption:'Taxable',value:0 },
    { id: 'serviceCharge', name: 'Service Charge', taxOption:'Taxable',value:0 },
    { id: 'serviceChargeDeduction', name: 'Service Charge Deduction', taxOption:'Taxable',value:-1 },
    { id: 'backPay', name: 'Back Pay', taxOption:'NonTaxable',value:0 },
    { id: 'otherDeduction', name: 'Other Deduction From Earning', taxOption:'Taxable', value:-1 },
    { id: 'Overtime', name: 'Overtime', taxOption:'Taxable',value:0 },
    { id: 'leave', name: 'Leave Encashment', taxOption:'Taxable',value:0 },
    { id: 'shiftAllowance', name: 'Shift Allowance', taxOption:'Taxable', value:0 },
    { id: 'onCallAllowance', name: 'On Call Allowance', taxOption:'Taxable', value:0 },
    { id: 'benefitInKind', name: 'Benefit in Kind', taxOption: 'TaxCovered', value: 0 },
    {id: 'actingAllowance', name: 'Acting Allowance', taxOption: 'Taxable', value: 0 },
    { id: 'other', name: 'Other', taxOption:'Taxable', value:0 },
]

export const deductionTypes= [{type:'advance',name:'Advance Settlement',cash:true},{type:'loan',name:'Employee Loan Repayment',cash:true},{type:'other',name:'Other',cash:false},{type:'damage',name:'Damage',cash:true},{type:'tax',name:'Tax Paid on behalf',cash:true}]

