import React, { Fragment, Component } from 'react';
import {Confirm} from 'react-admin';

class WithConfirm extends Component {
    state = { isOpen: false };
    event = null;
    constructor(props) {
        super(props);
        this.button = React.createRef();
    }

    handleClick = e => {
        this.setState({ isOpen: true });
        e.stopPropagation();
    };

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    handleConfirm = () => {
        this.button.current.firstElementChild.click();
        this.setState({ isOpen: false });
        this.props.onConfirm();
    };

    render() {
        const { children, title, content, ...rest } = this.props;
        return (
            <Fragment>
                <span onMouseDown={this.handleClick}>
                    <span ref={this.button}>
                        {React.cloneElement(children, rest)}
                    </span>
                </span>
                <Confirm
                    isOpen={this.state.isOpen}
                    title={title}
                    content={content}
                    onConfirm={this.handleConfirm}
                    onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }
}

WithConfirm.defaultProps = {
    title: 'ra.message.are_you_sure',
    content: 'ra.message.irreversible',
};

export default WithConfirm;
