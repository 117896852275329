// in src/customRoutes.js
import React from 'react';
import {Route} from 'react-router-dom';
import PayrollView from "./components/payroll/payrollView";
import PayrollReportView from "./components/payroll/payrollReportView";
import PayrollReports from "./components/report/payrollReports";
import Reports from "./components/report/reports";
import ListPayrolls from "./components/report/listPayrolls";
import CompanySelect from "./components/companySelect/companySelect";
import AdminDashboard from "./components/admin/adminDashboard";
import {ProfileEdit} from "./components/entities/profile";
import PayrollHistory from "./components/payroll/payrollRunHistory";
import PayslipEmailTemplate from "./components/report/payslipEmailTemplate";
import VarianceReport from "./components/report/varianceReport";
import OutdatedPage from "./components/outdatedPage";
import CompanyAuthError from "./components/companyAuthError";
import ServerErrorPage from "./UIComponents/ErrorPages/serverError";
import PaydayDocs from "./UIComponents/DocPages/paydayDocs";
import ConsolidatedPayrollReport from "./components/report/consolidatedReport/consolidatedPayrollReport";
import EmployeeReport from "./components/report/employeeReport";
import EmployeePayrollStatement from "./components/report/statements/EmployeePayrollStatement";
import PayrollLanding from "./components/payroll/payrollLanding";


export default [

    <Route exact path="/runPayroll/:id?" component={PayrollView} />,
    <Route  path="/consolidatedReport" component={ConsolidatedPayrollReport} />,
    <Route exact path="/admin" component={AdminDashboard} />,
    <Route exact path="/runHistory"  component={PayrollHistory} />,
    <Route exact path="/runPayroll/:id/reports"  component={PayrollReportView} />,
    <Route exact path="/runPayroll/:id/email"  component={PayslipEmailTemplate} />,
    <Route  path="/reports/payroll/:id"  component={PayrollReports} />,
    <Route  path="/reports/payroll"  component={ListPayrolls} />,
    <Route  path="/outdated"  noLayout component={OutdatedPage} />,
    <Route  path="/companyError"  noLayout component={CompanyAuthError} />,
    <Route  path="/reports/variance"  component={VarianceReport} />,
    <Route  path="/reports/employee"  component={EmployeeReport} />,
    <Route  path="/reports/payrollStatement"  component={EmployeePayrollStatement} />,
    <Route exact path="/serverError"  component={ServerErrorPage} />,
    <Route
        path="/selectCompanies"
        component={CompanySelect}
        noLayout
    />,
    <Route
        path="/help"
        component={PaydayDocs}
      />,
    <Route
           key="my-profile"
             path="/my-profile"
             component={ProfileEdit}
         />,
    <Route   path="/reports"  component={Reports} />,
    <Route exact path="/payroll" component={PayrollLanding} />,
];
