import React, { useState, useRef } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Card,
    CardContent,
    Grid,
    Divider,
    Collapse,
    Typography,
    TableHead,
} from '@material-ui/core';
import { Add, Edit, Remove, ExpandMore, ExpandLess } from '@material-ui/icons';
import { Parser } from 'expr-eval';
import { normalize } from '../../Helpers/utilityFunctions';
import ExcelExport from "@progress/kendo-react-excel-export/dist/npm/ExcelExport";
import ExcelExportColumn from "@progress/kendo-react-excel-export/dist/es/ExcelExportColumn";
import { ExcelIcon } from "../../UIComponents/icons";
import { green } from "@material-ui/core/colors";
import moment from "moment";
import ReportHeader from "./reportHeader";
import CsvDownloader from 'react-csv-downloader';
import { CsvIcon } from "../../UIComponents/icons";
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
const defaultCostCenterAllocationItems = [
    {
        name: "Staff Salary",
        key: "salary",
        expression: "basicSalary",
        dimentions: [
            {
                field: "function",
                value: "Support"
            }, 
            {
                field: "function",
                value: "Technical"
            }
        ]
    },
    {
        name: "Termination Benefit",
        key: "termination",
        expression: "terminationbenefit",
        dimentions: [
            {
                field: "function",
                value: "Support"
            },
            {
                field: "function",
                value: "Technical"
            }
        ]
    },
    {
        name: "Fringe Benefit",
        key: "fringe",
        expression: "transportallowance+companyPension",
            dimentions: [
            {
                field: "function",
                value: "Support"
            },
            {   
                field: "function",
                value: "Technical"
            }
        ]
    },
    {
        name: " Health Insurance",
        key: "healthInsurance",
         expression: "healthinsurance",
         dimentions: [
            {
                field: "function",
                value: "Support"
            },
             {   
                field: "function",
                value: "Technical"
            }
         ]
     }
]

const AddOrEditAllocation = ({ open, handleClose, editingItem, onSubmit }) => {
    const [formData, setFormData] = useState({
        name: '',
        expression: '',
        dimentions: []
    });

    React.useEffect(() => {
        if (editingItem) {
            setFormData(editingItem);
        } else {
            setFormData({
                name: '',
                expression: '',
                dimentions: []
            });
        }
    }, [editingItem]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleDimensionChange = (index, field, value) => {
        const newDimensions = [...formData.dimentions];
        newDimensions[index] = {
            ...newDimensions[index],
            [field]: value
        };
        setFormData({
            ...formData,
            dimentions: newDimensions
        });
    };

    const addDimension = () => {
        setFormData({
            ...formData,
            dimentions: [...formData.dimentions, {
                field: '',
                value: ''
            }]
        });
    };

    const removeDimension = (index) => {
        const newDimensions = formData.dimentions.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            dimentions: newDimensions
        });
    };

    const handleSubmit = () => {
        onSubmit(formData);
        handleClose();
    };

    return (
        <Dialog 
            open={open} 
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                {editingItem ? "Edit Allocation" : "Add Allocation"}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                           
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            margin="normal"
                        />
                        <TextField
                          
                            label="Key"
                            name="key"
                            value={formData.key}
                            onChange={handleInputChange}
                            margin="normal"
                        />  
                       
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                            fullWidth
                            label="Expression"
                            name="expression"
                            value={formData.expression}
                            onChange={handleInputChange}
                            margin="normal"
                        />
                    </Grid>
              
                    <Grid item xs={12}>
                        <Divider />
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<Add />}
                            onClick={addDimension}
                            style={{ marginTop: 16 }}
                        >
                            Add Dimension
                        </Button>
                    </Grid>

                    {formData.dimentions.map((dimension, index) => (
                        <Grid item xs={12} key={index}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={5}>
                                    <TextField
                                        fullWidth
                                        label="Field"
                                        value={dimension.field}
                                        onChange={(e) => handleDimensionChange(index, 'field', e.target.value)}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Value"
                                        value={dimension.value}
                                        onChange={(e) => handleDimensionChange(index, 'value', e.target.value)}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton size="small" onClick={() => removeDimension(index)}>
                                        <Remove />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    {editingItem ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const CostCenterAllocationItemsView = ({ costCenterAllocationItems, setCostCenterAllocationItems }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);

    const handleEdit = (item) => {
        setEditingItem(item);
        setIsModalOpen(true);
    };

    const handleDelete = (record) => {
        setCostCenterAllocationItems(prevItems => 
            prevItems.filter(item => item.name !== record.name)
        );
    };

    const handleAdd = () => {
        setEditingItem(null);
        setIsModalOpen(true);
    };

    const handleSubmit = (formData) => {
        if (editingItem) {
            setCostCenterAllocationItems(prevItems =>
                prevItems.map(item =>
                    item.name === editingItem.name ? formData : item
                )
            );
        } else {
            setCostCenterAllocationItems(prevItems => [...prevItems, formData]);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={handleAdd}
                    style={{ marginBottom: 16 }}
                >
                    Add Allocation
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Expression</TableCell>
                            <TableCell>Dimensions</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                        {costCenterAllocationItems.map((item) => (
                            <TableRow key={item.name}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.expression}</TableCell>
                                <TableCell>
                                    {item.dimentions.map((dimension, index) => (
                                        <div key={index}>
                                            {dimension.field}: {dimension.value}
                                        </div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    <IconButton size="small" onClick={() => handleEdit(item)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => handleDelete(item)}>
                                        <Remove />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>

            <AddOrEditAllocation 
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                editingItem={editingItem}
                onSubmit={handleSubmit}
            />
        </Grid>
    );
};

const AllocationResultsTable = ({ results, columnTotals, grandTotal }) => {
    if (!results || results.length === 0) return null;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell rowSpan={2}>Cost Center</TableCell>
                    {results[0].items.map(item => (
                        <TableCell colSpan={2} key={item.name} align="center">
                            {item.name}
                        </TableCell>
                    ))}
                    <TableCell rowSpan={2}>Total</TableCell>
                </TableRow>
                <TableRow>
                    {results[0].items.map(item => (
                        item.dimensions.map(dim => (
                            <TableCell key={`${item.name}-${dim.name}`}>
                                {dim.name}
                            </TableCell>
                        ))
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {results.map((result, idx) => (
                    <React.Fragment key={result.costCenter.id}>
                        <TableRow>
                            <TableCell>{result.costCenter.code}</TableCell>
                            {result.items.map(item => (
                                item.dimensions.map(dim => (
                                    <TableCell key={`${item.name}-${dim.name}`}>
                                        {dim.value.toFixed(2)}
                                    </TableCell>
                                ))
                            ))}
                            <TableCell>{result.total.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell />
                            {result.items.map(item => (
                                item.dimensions.map(dim => (
                                    <TableCell key={`${item.name}-${dim.name}-acc`}>
                                        {dim.account ? dim.account.code : '-'}
                                    </TableCell>
                                ))
                            ))}
                            <TableCell />
                        </TableRow>
                    </React.Fragment>
                ))}
                <TableRow>
                    <TableCell>Total</TableCell>
                    {columnTotals.map((total, idx) => (
                        <TableCell key={idx}>{total.toFixed(2)}</TableCell>
                    ))}
                    <TableCell>{grandTotal.toFixed(2)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

// Utility functions for calculations
const calculateValue = (payrolls, expression, dimension, allocation) => {
    const parser = new Parser();
    
    return payrolls.reduce((total, payroll) => {
        if (payroll[dimension.field] !== dimension.value) return total;
      
        const costCenterAllocation = payroll.payrollCostCenterAllocations.find(
            a => normalize(a.costCenterCode) === normalize(allocation.code) 
        );

        // console.log(payroll.payrollCostCenterAllocations,allocation, costCenterAllocation);

        const allocationPercent = costCenterAllocation ? costCenterAllocation.allocationPercentage : 0;

        try {
            const expr = parser.parse(expression);
            const value = expr.evaluate(payroll);
            // console.log({value, expression, payroll});
            return total + (value * allocationPercent);
        } catch (error) {
            console.error('Error evaluating expression:', error, payroll);
            return total;
        }
    }, 0);
};

const findMatchingAccount = (accounts, { costCenterId, key, dimension }) => {
  
    const normalizedKey = normalize(key);
    const normalizedDimension = normalize(dimension);
    
    return accounts.find(account => 
        account.costCenterId === costCenterId &&
        account.tags && 
        account.tags.some(tag => normalize(tag) === normalizedDimension) &&
        account.tags.some(tag => normalize(tag) === normalizedKey)
    );
};

const AllocationExcelExport = ({ allocationResults, payrollDate, region, department, site }) => {
    const ref = useRef();

    // Transform allocation results into flat rows for excel
    const getExcelData = () => {
        if (!allocationResults) return [];
        
        return allocationResults.results.flatMap(result => 
            result.items.flatMap(item => 
                item.dimensions.map(dim => ({
                    costCenter: result.costCenter.code,
                    accountNumber: dim.account ? dim.account.code : '',
                    amount: dim.value,
                    narrative: `${region?region+'-':''}${department?department+'-':''}${site?site+'-':''}${item.name} - ${dim.name} - ${moment(payrollDate).format("[M]MM-YYYY")}`
                }))
            )
        );
    };

    const handleExport = () => {
        const options = ref.current.workbookOptions();
        const sheet = options.sheets[0];

        sheet.frozenRows = 3;
        sheet.name = "Cost Center Allocations";
        
        // Add title rows
        const title = [{
            value: `Cost Center Allocations - ${moment(payrollDate).format("MMMM YYYY")}`,
            fontSize: 20,
            textAlign: "center",
            background: "#098EF6",
            color: "#ffffff",
            bold: true
        }];

        sheet.rows.splice(0, 0, { cells: title, height: 40 });
        sheet.mergedCells = ['A1:D1']

        // Style all rows
        sheet.rows.forEach(row => {
            if (row.type === 'header') {
                row.height = 30;
                row.cells.forEach(cell => {
                    cell.borderLeft = {size: 1};
                    cell.borderRight = {size: 1};
                    cell.borderBottom = {size: 1};
                    cell.borderTop = {size: 1};
                    cell.fontSize = 12;
                    cell.background = "#BAD8FC";
                    cell.color = "#000";
                    cell.bold = true;
                });
            }
            if (row.type === 'data') {
                row.height = 25;
                row.cells.forEach(cell => {
                    cell.borderLeft = {size: 1};
                    cell.borderRight = {size: 1};
                    cell.borderBottom = {size: 1};
                    cell.borderTop = {size: 1};
                    cell.fontSize = 11;
                });
            }
        });

        ref.current.save(options);
    };

    return (
        <div style={{ marginTop: 16 }}>
            <Button 
                variant="outlined" 
                color="primary" 
                startIcon={<ExcelIcon style={{ color: green[500] }}/>} 
                onClick={handleExport}
            >
                Export Allocations
            </Button>

            <ExcelExport
                data={getExcelData()}
                ref={ref}
                fileName={`Payday_cost_center_allocations_${moment(payrollDate).format("MMM-YYYY")}`}
            >
                <ExcelExportColumn field="costCenter" title="Account CostCentre" width={150} />
                <ExcelExportColumn field="accountNumber" title="Account Number" width={150} />
                <ExcelExportColumn 
                    field="amount" 
                    title="Goods Amount" 
                    width={120}
                    cellOptions={{
                        format: "_(* #,##0.00_);_(* (#,##0.00);_(* \"-\"??_);_(@_)"
                    }}
                />
                <ExcelExportColumn field="narrative" title="Narrative" width={200} />
            </ExcelExport>
        </div>
    );
};

const AllocationCSVExport = ({ allocationResults, payrollDate, region, department }) => {
    if (!allocationResults) return null;

    const getCSVData = () => {
        const lastDayOfMonth = moment(payrollDate).endOf('month').format('MM/DD/YYYY');
        const reference = `JL${moment(payrollDate).format('YYMM')}006`;

        return allocationResults.results.flatMap(result => 
            result.items.flatMap(item => 
                item.dimensions.map(dim => ({
                    AccountCostCentre: result.costCenter.code,
                    AccountDepartment: department || 'M24',
                    AccountNumber: dim.account ? dim.account.code : '',
                    TransactionType: '1',
                    TransactionDate: lastDayOfMonth,
                    GoodsAmount: dim.value.toFixed(2),
                    Reference: reference,
                    Narrative: `${region ? region + '-' : ''}${item.name} - ${dim.name} - ${moment(payrollDate).format("[M]MM-YYYY")}`,
                    UserNumber: '4',
                    Source: '3',
                    PostedDate: lastDayOfMonth
                }))
            )
        );
    };

    return (
        <CsvDownloader
            filename={`Payday_sage_import_${moment(payrollDate).format("MMM-YYYY")}`}
            extension=".csv"
            separator=","
            bom={false}
            columns={[
                "AccountCostCentre",
                "AccountDepartment",
                "AccountNumber",
                "TransactionType",
                "TransactionDate",
                "GoodsAmount",
                "Reference",
                "Narrative",
                "UserNumber",
                "Source",
                "PostedDate"
            ]}
            datas={getCSVData()}
        >
            <Button
                variant="outlined"
                color="primary"
                startIcon={<CsvIcon style={{ color: green[500] }}/>}
            >
                Download SAGE Import
            </Button>
        </CsvDownloader>
    );
};

const PayrollCostCenterAllocations = ({ logo, company, filteredPayrolls, payrollRun, costCenters, accounts, payrollDate, region, department, site }) => {
    const [costCenterAllocationItems, setCostCenterAllocationItems] = useState(defaultCostCenterAllocationItems);
    const [expanded, setExpanded] = useState(false);
    const [allocationResults, setAllocationResults] = useState(null);
    const componentRef = useRef();

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleAllocate = () => {
        

        
        const results = costCenters.map(costCenter => ({
            costCenter,
            items: costCenterAllocationItems.map(item => ({
                name: item.name,
                dimensions: item.dimentions.map(dim => {
                    const account = findMatchingAccount(accounts, {
                        costCenterId: costCenter.id,
                        key: item.key,
                        dimension: dim.value
                    });
                    
                    return {
                        name: dim.value,
                        value: calculateValue(filteredPayrolls, item.expression, dim, costCenter),
                        account: account
                    };
                })
            })),
            get total() {
                return this.items.reduce((sum, item) => 
                    sum + item.dimensions.reduce((dimSum, dim) => dimSum + dim.value, 0)
                , 0);
            }
        }));

        const columnTotals = results[0].items.flatMap(item =>
            item.dimensions.map((_, dimIdx) =>
                results.reduce((sum, result) =>
                    sum + result.items.find(i => i.name === item.name)
                        .dimensions[dimIdx].value
                , 0)
            )
        );

        const grandTotal = results.reduce((sum, result) => sum + result.total, 0);

        setAllocationResults({ results, columnTotals, grandTotal });
    };

    return (
        <div>
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        {expanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography variant="h6">
                        Cost Center Allocations
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAllocate}
                    >
                        Allocate
                    </Button>
                </Grid>
            </Grid>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CostCenterAllocationItemsView 
                    costCenterAllocationItems={costCenterAllocationItems}
                    setCostCenterAllocationItems={setCostCenterAllocationItems}
                />
            </Collapse>
            {allocationResults && (<>
                <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
                    <AllocationExcelExport 
                        allocationResults={allocationResults}
                        payrollDate={payrollDate}
                        region={region}
                        department={department}
                        site={site}
                    />
                    <AllocationCSVExport 
                        allocationResults={allocationResults}
                        payrollDate={payrollDate}
                        region={region}
                        department={department}
                    />
                      <ReactToPrint
                        trigger={() => <Button variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print/PDF</Button>}
                        content={() => componentRef.current}
                        pageStyle="@page { size: A4 landscape;} @media print { body { -webkit-print-color-adjust: exact;} }"
                    />
                </div>
                <div ref={componentRef} style={{marginTop:16}}>
                <ReportHeader logo={logo} payrollRun={payrollRun} title="Cost Center Allocations"/>
                <AllocationResultsTable {...allocationResults} />
                </div>
            </>)}
        </div>
    );
};

export default PayrollCostCenterAllocations;