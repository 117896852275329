import React, {useState} from "react";

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const useAsyncState = initialState => {
    const [state, setState] = useState(initialState);

    const asyncSetState = value => {
        return new Promise(resolve => {
            setState(value);
            setState((current) => {
                resolve(current);
                return current;
            });
        });
    };

    return [state, asyncSetState];
};

export const normalize = (str) => str.replace(/ /g, '').toLowerCase();