import React, { useEffect, useState } from 'react';
import { useQueryWithStore } from 'react-admin';
import { Card, CardContent, Grid, Typography, Button, Divider, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { indexedDBService } from '../../services/indexedDBService';
import MultiCurrencyField from '../../UIComponents/multiCurrencyField';
import { useSelector } from 'react-redux';

const PayrollLanding = () => {
    const [checkpoints, setCheckpoints] = useState([]);
    // const [payrollRuns, setPayrollRuns] = useState([]);
    const { data: payrollRuns, loaded } = useQueryWithStore({
        type: 'getList',
        resource: 'payrollRuns',
        payload: { 
            filter: {},
            pagination: { page: 1, perPage: 5 },
            sort: { field: 'runDate', order: 'DESC' }
        }
    });

    // const [companyId, setCompanyId] = useState(null);
    const { currentCompany } = useSelector(state => state.user);

    // useEffect(() => {
    //     if (currentCompany && currentCompany.id) {
    //         setCompanyId(currentCompany.id);
    //     }
    // }, [currentCompany]);

    useEffect(() => {
        const loadCheckpoints = async () => {
            try {
                const allCheckpoints = await indexedDBService.getCheckpoints();
                // Filter checkpoints by companyId
                const filteredCheckpoints = allCheckpoints.filter(cp => cp.companyId === currentCompany.id);
                // Get only recent checkpoints (last 5)
                const sortedCheckpoints = filteredCheckpoints
                    .sort((a, b) => moment(b.savedOn) - moment(a.savedOn))
                    .slice(0, 5);
                setCheckpoints(sortedCheckpoints);
            } catch (error) {
                console.error('Failed to load checkpoints:', error);
            }
        };

        loadCheckpoints();
    }, []);

    const formatDate = (dateString) => {
        return moment(dateString).format('DD/MM/YY h:mm A');
    };

    // console.log(data, loaded)

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={7}>
                        <div style={{ textAlign: 'start', padding: '40px 0' }}>
                            <Button
                                component={Link}
                                to="/runPayroll"
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<AddIcon />}
                                style={{ padding: '20px 40px', fontSize: '1.2em' }}
                            >
                                Start New Payroll
                            </Button>
                        </div>
 <Typography variant="h6" gutterBottom>
                            Recent Payroll Runs
                        </Typography>
                        {loaded && payrollRuns && (
                            <List>
                                {payrollRuns.map(run => (
                                    <ListItem 
                                        key={run.id}
                                        button
                                        component={Link}
                                        to={`/runPayroll/${run.id}`}
                                    >
                                        <ListItemText
                                            primary={moment(run.payrollDate).format('MMMM YYYY')}
                                            secondary={
                                                <>
                                                    <Typography component="span" variant="body2" color="textPrimary">
                                                        {run.status} • {run.createdBy}
                                                    </Typography>
                                                    <br />
                                                    Run on {moment(run.runDate).format('DD/MM/YY HH:mm')}
                                                </>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <Typography variant="body2">
                                                <MultiCurrencyField record={run} source="cash" />
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Grid>

                    <Grid item xs={1}>
                        <Divider orientation="vertical" style={{ height: '100%' }} />
                    </Grid>

                    <Grid item xs={4}>
                       
                    {checkpoints.length > 0 && (
                            <div style={{ marginTop: 40 }}>
                                <Typography variant="h6" gutterBottom>
                                    Recent Checkpoints
                                </Typography>
                                <List>
                                    {checkpoints.map((checkpoint) => (
                                        <ListItem 
                                            key={checkpoint.checkpointId}
                                            button
                                            component={Link}
                                            to={`/runPayroll?checkpoint=${checkpoint.checkpointId}`}
                                        >
                                            <ListItemText
                                                primary={
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {checkpoint.autosaved ? (
                                                            <AutorenewIcon style={{ marginRight: 8, color: '#666' }} />
                                                        ) : (
                                                            <SaveIcon style={{ marginRight: 8 }} />
                                                        )}
                                                        {checkpoint.autosaved ? 'Autosaved' : moment(checkpoint.payrollDate).format('MMM YYYY')} Payroll
                                                    </div>
                                                }
                                                secondary={`${checkpoint.autosaved ? 'Last autosaved' : 'Saved by'} ${checkpoint.savedBy} on ${formatDate(checkpoint.savedOn)}`}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PayrollLanding; 