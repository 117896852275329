// in src/posts.js
import React, {Fragment} from 'react';
import {
    AutocompleteInput, Create,
    Datagrid, Edit,
    EditButton, FormDataConsumer,
    List,
    ReferenceField,
    ReferenceInput, required, SelectInput,
    Show, SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    NumberField,
    NumberInput,
ShowButton
} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import {useForm} from "react-final-form";
import {parse} from "query-string";
import DefaultListActions, {Empty} from "./common";

export const SiteList = props => (
    <List   exporter={exporterCommon(props.resource)} actions={<DefaultListActions/>} {...props} empty={<Empty />} >
        <Datagrid rowClick='show' >
            <TextField source="name" />
            <TextField source="code" />
            <ReferenceField source='regionId' reference='regions' >
                <TextField source='name' />
            </ReferenceField>
            {/*<TextField source="title" />*/}
            {/*<ShowButton />*/}
            <EditButton />


        </Datagrid>
    </List>
);


export const SiteShow = props => (
    <Show  {...props}>
        <SimpleShowLayout>
            <TextField source='name' />
            <TextField source='code' />
            <ReferenceField source='regionId' reference='regions' >
                <TextField source='name' />
            </ReferenceField>
            <NumberField source="allowanceValue" />
        </SimpleShowLayout>
    </Show>


);


export const SiteEdit = props => {

    return <Edit {...props}>
        <SimpleForm redirect='list'  >
            <TextInput source='name' />
            <TextInput source='code' />
            <ReferenceInput source='regionId' reference='regions' >
                <SelectInput source='name' />
            </ReferenceInput>
            <NumberInput source="allowanceValue"/>
        </SimpleForm>
    </Edit>

};


export const SiteCreate = props => {

    return <Create {...props}  >

        <SimpleForm redirect='list'   >
                <TextInput source='name' />
             <TextInput source='code' />
             <ReferenceInput source='regionId' reference='regions' >
                <SelectInput source='name' />
            </ReferenceInput>
            <NumberInput source="allowanceValue"/>
        </SimpleForm>



    </Create>
};
