import React, { Fragment, useEffect, useRef, useState } from 'react';
import AddIcon from "@material-ui/icons/Add";
import { Link } from 'react-router-dom';
import { useForm } from 'react-final-form';

import {
    ArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanField,
    BooleanInput,
    BulkDeleteButton,
    Button,
    Create,
    Datagrid,
    DateField,
    DeleteButton,
    Edit,
    Filter,
    FormDataConsumer,
    FormTab,
    List,
    maxLength,
    minLength,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    Show,
    SimpleFormIterator,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput,
    useDataProvider,
    useNotify,
    useQueryWithStore,
    useRefresh,
    useUnselectAll,
    useUpdateMany,
    EditButton,
    ShowButton, CheckboxGroupInput, useGetMany
} from 'react-admin';
import { AddNewDeductionButton, AddNewExpenseButton, EditXButton, EditButtonWithEmployeeId } from "./positions";

import { DateInput as DateInputO } from 'react-admin-date-inputs2';
import { DateInput } from "../../UIComponents/materialDateInput";
import _ from "lodash";
import exporterCommon from "../import/exporterCommon";
import DefaultListActions, { AddItemButton, Empty } from "./common";
import MultiCurrencyField from "../../UIComponents/multiCurrencyField";
import { AddressTab, AddressTabShow } from "./addresses";
import PercentField from "../../UIComponents/PercentField";

import ActiveIcon from "@material-ui/icons/Check"
import SyncIcon from "@material-ui/icons/Sync"
import DeactivateIcon from "@material-ui/icons/Remove"
import EditIcon from "@material-ui/icons/Edit";
import { EndDateInput } from "../../UIComponents/endDateInput";
import ConditionalField from "../../UIComponents/conditionalField";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import EmployeeBulkActionDialog from "../dialogs/employeeBulkActionsDialog";
import MoveToSitePopover from "../dialogs/moveToSitePopover";
import moment from "moment";
import CaptionedField from "../../UIComponents/captionedField";
import { Typography } from "../../UIComponents/Wrappers";
import ReactToPrint from "react-to-print";
import { useDispatch, useStore } from "react-redux";
import { FormControl } from "@material-ui/core";
import { calculateGross, calculateNetPay, getNetPay } from "../../Helpers/salaryCalculator";
import Zemen from "../../UIComponents/ZemenCalendar/zemen";
import ChangeEndDatePopover from "../dialogs/extendDatePopover";
import Divider from "@material-ui/core/Divider";
import ChangeEmploymentTypePopover from "../dialogs/changeEmploymentTypePopover";
import MoveToDepartmentPopover from "../dialogs/moveToDepartmentPopover";
import MoveToCostCenterPopover from '../dialogs/moveToCostCenterPopover';
import ChangeStartDatePopover from "../dialogs/changeStartDatePopover";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { EmployeeAdjustmentCreate } from './employeeAdjustments';
import { ApproveButton, AdjustmentValueField } from './employeeAdjustments';

const ActiveEmployeeButton = ({ label, active, selectedIds, loading, redirect, record }) => {
    // const {, record,redirect,id, } = props
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany] = useUpdateMany(
        'employees',
        selectedIds,
        { active },
        {
            onSuccess: () => {
                refresh();
                notify(active ? "Employees Activated" : "Employees Deactivated");
                unselectAll('employees');
            },
            onFailure: error => notify('Error: Employees not updated', 'warning'),
        }
    );



    return (
        <Button
            label={label}
            onClick={updateMany}
        >
            {active ? <ActiveIcon /> : <DeactivateIcon />}
        </Button>
    );
};

const client = localStorage.getItem('client') ? localStorage.getItem('client').toLowerCase() : process.env.REACT_APP_CLIENT;


const EmployeeBulkActionButtons = props => (
    <Fragment>
        <MoveToSitePopover label="Move to Site" {...props} />
        <MoveToDepartmentPopover label="Move to Department" {...props} />
        <MoveToCostCenterPopover label="Move to CostCenter" {...props}/>
        <ChangeEmploymentTypePopover label="Change Employment Type" {...props} />

        <ChangeEndDatePopover label="Change End Date" {...props} />
        <ChangeStartDatePopover label="Change Start Date" {...props} />

        {props.filterValues.active && <ActiveEmployeeButton active={false} label="Deactivate Selected" {...props} />}
        {!props.filterValues.active && <ActiveEmployeeButton active={true} label="Activate Selected" {...props} />}

        <EmployeeBulkActionDialog label="Other Actions" {...props} />
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} />
    </Fragment>
);



const EmployeeFilter = (props) => (
    <Filter {...props}>
        <BooleanInput source="active" alwaysOn />
        <ReferenceInput perPage={1000} label="Employee Name" emptyText="All Employees" allowEmpty source="id" reference="employees" alwaysOn >
            <AutocompleteInput allowEmpty={true} optionText={(record) => record.fullname ? (record.orgID || '') + " " + record.fullname : "All Employees"} options={{ type: 'search' }} />
        </ReferenceInput >
        <ReferenceInput perPage={1000} label="Department" source="departmentId" reference="departments" >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput perPage={1000} label="CostCenter" source="costCenterId" reference="costCenters" >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput perPage={1000} label="Current Site" source="siteId" reference="sites" >
            <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput perPage={1000} label="Position" source="positionId" reference="positions" >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <SelectInput defaultValue={"FullTime"} source="employmentType" choices={[
            { id: 'FullTime', name: 'FullTime' },
            { id: 'Contract', name: 'Contract' },
            { id: 'PartTime', name: 'PartTime' },
            { id: 'InternOrFellow', name: 'InternOrFellow' },
            { id: 'Casual', name: 'Casual' },
        ]} />


        <SelectInput defaultValue={"Ethiopian"} source="citizenship" choices={[
            { id: 'Ethiopian', name: 'Ethiopian' },
            { id: 'Non-Ethiopian', name: 'Non-Ethiopian' }
        ]} />
    </Filter>
);
const postRowStyle = (record, index) => ({
    backgroundColor: record.contractEndDate && moment(record.contractEndDate) > moment("1970/1/1") && moment(record.contractEndDate) < moment() ? '#ffcb7d' : 'white',
});
const PrintContainer = props =>
    <div ref={props.comRef}><Datagrid rowStyle={postRowStyle} {...props} rowClick="show">
        <TextField label="Work Id" source="orgID" />
        <TextField source="fullname" />
        <MultiCurrencyField source="basicSalary" />
        <ReferenceField source="positionId" reference="positions">
            <TextField source="name" />
        </ReferenceField>
        <TextField source="employmentType" />
        <ReferenceField source="siteId" reference="sites">
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="costCenterId" reference="costCenters">
            <TextField source="name" />
        </ReferenceField>
        <DateField source="contractEndDate" />
        <ShowButton label={'Detail'} className='no-print' />
        <EditButton className='no-print' />
    </Datagrid>
    </div>

const EmployeeActions = props => {
    const dispatch = useDispatch();
    return <>
        {localStorage.getItem('companyMateId') && <Button startIcon={<SyncIcon />} label="Sync" onClick={() => dispatch({ type: 'LOAD_TIK_MATES_CHANGES' })} />}
        {/*<DefaultListActions {...props}  />*/}
    </>
}

export const EmployeeList = props => {
    const componentRef = useRef();
    return <List perPage={150} className="all-boarder" bulkActionButtons={<EmployeeBulkActionButtons />} exporter={exporterCommon(props.resource)}
        actions={<DefaultListActions extra={<EmployeeActions />} componentRef={componentRef} />} empty={<Empty />} filters={<EmployeeFilter />}
        filterDefaultValues={{ active: true }}   {...props}>
        <PrintContainer comRef={componentRef} />
    </List>



    // <List ref={componentRef} className="all-boarder" perPage={1000} bulkActionButtons={<EmployeeBulkActionButtons/>} exporter={exporterCommon(props.resource)}
    //       actions={<DefaultListActions  />} empty={<Empty/>} filters={<EmployeeFilter/>}
    //       filterDefaultValues={{active: true}}   {...props}>
    //     <Datagrid  rowClick="show">
    //         <TextField label="Work Id" source="orgID"/>
    //         <TextField source="fullname"/>
    //         <MultiCurrencyField source="basicSalary"/>
    //         <ReferenceField source="positionId" reference="positions">
    //             <TextField source="name"/>
    //         </ReferenceField>
    //         <TextField source="employmentType"/>
    //         <BooleanField source="active"/>
    //         <EditButton/>
    //     </Datagrid>
    // </List>

};

export const EmployeeEdit = props => (
    <Edit {...props}>
        <TabbedForm redirect='list'  >
            {/*<FormTab label="Basic Info">*/}
            {/*<BooleanInput  source="active"/>*/}

            {/*<ReferenceInput  label="Position" source="positionId" reference="positions" validate={[required()]}>*/}
            {/*<SelectInput optionText="name" />*/}
            {/*</ReferenceInput>*/}
            {/*<TextInput source="name" />*/}
            {/*<TextInput    source="fatherName" />*/}
            {/*<TextInput   source="gFatherName" />*/}
            {/*<SelectInput  source="gender"  choices={[*/}
            {/*{ id: 'Female', name: 'Female' },*/}
            {/*{ id: 'Male', name: 'Male' },*/}
            {/*]}  />*/}
            {/*<TextInput   source="tinNo" validate={[maxLength(15)]} />*/}
            {/*<TextInput   source="pensionNo" validate={[maxLength(15)]} />*/}
            {/*<SelectInput  defaultValue={"Ethiopian"} source="citizenship"  choices={[*/}
            {/*{ id: 'Ethiopian', name: 'Ethiopian' },*/}
            {/*{ id: 'Foreign', name: 'Foreign' },*/}
            {/*]}  />*/}
            {/*<SelectInput defaultValue={"FullTime"} source="employmentType"  choices={[*/}
            {/*{ id: 'FullTime', name: 'FullTime' },*/}
            {/*{ id: 'Contract', name: 'Contract' },*/}
            {/*]}  />*/}
            {/*<DateInput source="contractEndDate"  />*/}
            {/*<ReferenceInput   filter={{ expand:'bank' }}  label="Bank" source="companyBankId" reference="companyBanks" validate={[required()]}>*/}
            {/*<SelectInput optionText="bank.name" />*/}
            {/*</ReferenceInput>*/}
            {/*<TextInput label="Branch"   source="bankBranch" />*/}
            {/*<TextInput   source="bankAccountNumber" />*/}
            {/*<NumberInput    source="basicSalary" />*/}
            {/*<TextInput label='Email' type='email' validate={email()}   source="address.email" />*/}
            {/*</FormTab>*/}
            <FormDataConsumer>
                {formDataProps => (
                    <EmployeeBasic {...formDataProps} />
                )}
            </FormDataConsumer>
            <AddressTab />
            <FormDataConsumer>
                {formDataProps => (
                    <MiscForm {...formDataProps} />
                )}
            </FormDataConsumer>
        </TabbedForm>

    </Edit>
);

const ChangeEarnings = (positionId, dataProvider, form, employmentType, citizenship) => {

    dataProvider.getOne('positions', { id: positionId, expand: 'earnings,deductions,expenses,department' })
        .then(({ data }) => {



            const e = data.earnings.filter(e => e.appliesTo.includes(employmentType) && (!citizenship || !e.appliesToCitizens || !e.appliesToCitizens.length || e.appliesToCitizens.map(ap => ap.toLowerCase()).includes(citizenship.toLowerCase().replace(/\s+/g, '')))).map(e => {
                // console.log(e)
                const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag", "positionId", "employeeId"])
                f.parentScope = f.scope
                f.scope = "Employee"
                f.copyRefId = e.id
                //  f.earningType = e.earningType
                return f
            })

            const d = data.deductions.filter(e => e.appliesTo.includes(employmentType) && (!citizenship || !e.appliesToCitizens || !e.appliesToCitizens.length || e.appliesToCitizens.map(ap => ap.toLowerCase()).includes(citizenship.toLowerCase().replace(/\s+/g, '')))).map(e => {
                const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag", "positionId", "employeeId"])
                f.parentScope = f.scope
                f.scope = "Employee"
                f.copyRefId = e.id
                return f
            })

            const ex = data.expenses.filter(e => e.appliesTo.includes(employmentType) && (!citizenship || !e.appliesToCitizens || !e.appliesToCitizens.length || e.appliesToCitizens.map(ap => ap.toLowerCase()).includes(citizenship.toLowerCase().replace(/\s+/g, '')))).map(e => {
                const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag", "positionId", "employeeId"])
                f.parentScope = f.scope
                f.scope = "Employee"
                f.copyRefId = e.id
                return f
            })

            //  console.log(e,data.earnings,form.getFieldState('earnings').value.filter(er=>er.parentScope!=="Position").concat(e))
            //
            //   const ee = (form.getFieldState('earnings').value||[]).filter(er=>er.parentScope!=="Position").concat(e)
            //   console.log(ee)
            if (data.department) {
                form.change('departmentId', data.department.id)
            }
            if(data.basicSalary){
                form.change('basicSalary', data.basicSalary)
            }


            form.change('earnings', (form.getFieldState('earnings').value || []).filter(er => er.parentScope !== "Position").concat(e))
            //console.log(form.getFieldState('earnings').value)
            form.change('deductions', (form.getFieldState('deductions').value || []).filter(er => er.parentScope !== "Position").concat(d))
            form.change('expenses', (form.getFieldState('expenses').value || []).filter(er => er.parentScope !== "Position").concat(ex))
        })
}

const setDefaults = (employmentType, form, data, citizenship, dataProvider) => {
    //  console.log(employmentType,form)
    // form.reset()

    // return   dataProvider.getOne('companies', { id: localStorage.getItem('companyId'), expand:`earnings($filter = Scope eq 'Company'),deductions($filter = Scope eq 'Company'),expenses($filter = Scope eq 'Company'),CompanySettings`})
    //       .then(({ data }) => {
    if (data) {
        const e = data.earnings.filter(e => e.appliesTo.includes(employmentType) && (!citizenship || !e.appliesToCitizens || !e.appliesToCitizens.length || e.appliesToCitizens.map(ap => ap.toLowerCase()).includes(citizenship.toLowerCase().replace(/\s+/g, '')))).map(e => {

            const f = _.omit(e, ["id", "appliesTo", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag", "positionId", "employeeId"])
            f.parentScope = f.scope
            f.scope = "Employee"
            f.copyRefId = e.id
            return f


        })

        const d = data.deductions.filter(e => e.appliesTo.includes(employmentType) && (!citizenship || !e.appliesToCitizens || !e.appliesToCitizens.length || e.appliesToCitizens.map(ap => ap.toLowerCase()).includes(citizenship.toLowerCase().replace(/\s+/g, '')))).map(e => {

            const f = _.omit(e, ["id", "appliesTo", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag", "positionId", "employeeId"])
            f.parentScope = f.scope
            f.scope = "Employee"
            f.copyRefId = e.id
            return f

        })

        const ex = data.expenses.filter(e => e.appliesTo.includes(employmentType) && (!citizenship || !e.appliesToCitizens || !e.appliesToCitizens.length || e.appliesToCitizens.map(ap => ap.toLowerCase()).includes(citizenship.toLowerCase().replace(/\s+/g, '')))).map(e => {

            const f = _.omit(e, ["id", "appliesTo", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag", "positionId", "employeeId"])
            f.parentScope = f.scope
            f.scope = "Employee"
            f.copyRefId = e.id
            return f

        })

        //console.log(e)
        //  if(e.fullTime)
        // console.log('deductions',ex)

        form.change('earnings', e)
        form.change('deductions', d)
        form.change('expenses', ex)


        if (form.getFieldState('positionId') && form.getFieldState('positionId').value) {
            ChangeEarnings(form.getFieldState('positionId').value, dataProvider, form, employmentType, citizenship)
        }


        if (data.companySettings && data.companySettings.find(c => c.name === "payGross") && data.companySettings.find(c => c.name === "payGross").value === 'true') {

            form.change('payGross', true)
            form.change('otBase', 'gross')
        }
        // return data
        // })
    }

}
const MiscForm = props => {
    const form = useForm();
    const [net, setNet] = useState(0);
    const [basic, setBasic] = useState(0);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const store = useStore();
    //  const {data: fullEmployee } = useQueryWithStore({
    //      type: 'getOne',
    //      resource: 'employees',
    //      payload: {id: props.formData.id, expand:`earnings,deductions`}
    //  });
    //
    //  const {data: taxRules } = useQueryWithStore({
    //      type: 'getList',
    //      resource: 'incomeTaxRules',
    //      payload: {pagination:{},sort:{},filter:{}}
    //  });
    //
    //  // console.log({fullEmployee,taxRules})
    // let net = 0;
    //  useEffect( () =>  {
    //      if(fullEmployee&&taxRules)
    //     net = getNetPay({basicSalary:fullEmployee.basicSalary,payrollFixedEarnings:fullEmployee.earnings,payrollFixedDeductions:fullEmployee.deductions, taxRules})
    //      console.log(net)
    //  }, [fullEmployee,taxRules])
    //

    const handleGetNet = async () => {
        setLoading(true)
        try {
            const desiredNet = props.formData.desiredNet
            const { basic, net } = await calculateGross(props.formData.id, desiredNet, dataProvider, store)
            // console.log({basic,net})
            setBasic(basic)
            form.change("basicSalary", basic)
            form.change("desiredNet", desiredNet)
            // const net =   await calculateNetPay(props.formData.id,dataProvider,store, basic)
            setNet(net)
            setLoading(false)
        }
        catch (e) {
            setError(e.message)
            setLoading(false)
        }

        // console.log(net)
    }


    return <FormTab {...props} label="Miscellaneous">
        <Grid fullWidth container>
            <Grid item md={6}>
                <div>
                    <NumberInput source="outstandingLoan" />
                </div>

                <NumberInput source="remainingLeaveDays" />
                <div>
                    <NumberInput source="desiredNet" />

                    <Button disabled={loading} onClick={handleGetNet} label={`Gross Up`} />
                    {net > 0 && basic > 0 && <div style={{ width: '75%' }}>
                        Basic Salary is Set to ~ {basic.toFixed(3)} to Get Net ~ {net.toFixed(2)}
                    </div>}
                    {error && <div style={{ width: '75%', color: 'red' }}>
                        Please Check the Desired Net Value
                    </div>}
                </div>


                {/*<NumberInput source="defaultOvertime" />*/}
                <SelectInput defaultValue={"ETB"} source="defaultCurrency" choices={[
                    { id: 'ETB', name: 'ETB' },
                    { id: 'USD', name: 'USD' },
                    { id: 'EUR', name: 'EUR' },
                    { id: 'GBP', name: 'GPB' },
                ]} />

                <BooleanInput source="payGross" />

                <SelectInput defaultValue={"basic"} source="otBase" label="Overtime Base" choices={[
                    { id: 'basic', name: 'Basic Salary' },
                    { id: 'gross', name: 'Basic + Fixed Earnings' },
                ]} />

                <BooleanInput source="excludeFromDistEarnings" />
                <NumberInput label="Daily Service Hours " source="dailyServiceHour" />



            </Grid>
            <Grid item md={6}>
                <TextInput label="National ID" source="nationalID" />
                <TextInput source="taxRegion" />
                <TextInput source="function" />
                <TextInput source="subFunction" />
                <TextInput source="grade" />

                <Divider />
                <NumberInput label="Extra Income Tax " source="extraIncomeTax" />
                <TextInput placeholder="Eg. taxableIncome * 0.35" fullWidth label="Custom IncomeTax Expression" source="customIncomeTaxExpression" />

            </Grid>

            <Grid item md={12}>
                <CheckboxGroupInput source="sendPayslipVia" choices={[
                    { id: "Publish", name: 'Publish to Self Service Portal' },
                    { id: "EmailLink", name: 'Email link after Publish' },
                    { id: "Telegram", name: 'Send As Telegram Message' },
                    { id: "DirectEmail", name: 'Directly Email Full Slip' },
                    { id: "None", name: 'Dont Send Payslip' },
                ]} />
            </Grid>

        </Grid>

    </FormTab>

}

const EmployeeBasic = props => {
    const form = useForm();

    const { data: companyData } = useQueryWithStore({
        type: 'getOne',
        resource: 'companies',
        payload: { id: localStorage.getItem('companyId'), expand: `earnings($filter = Scope eq 'Company'),deductions($filter = Scope eq 'Company'),expenses($filter = Scope eq 'Company'),CompanySettings` }
    });

    const { data: companyBanks } = useQueryWithStore({
        type: 'getList',
        resource: 'companyBanks',
        payload: { filter: { expand: `bank` } }
    });
    // console.log(companyBanks)





    //  console.log(form)
    const dataProvider = useDataProvider();
    useEffect(() => {
        if (props.create) {
            //console.log(form)

            setDefaults('FullTime', form, companyData, "Ethiopian", dataProvider)

        }
    }, [companyData])

    const setBank = (e) => {
        const bank = companyBanks.find(b => b.id === e.target.value)
        bank && form.change('bank', bank.bank.name)
        // console.log( bank.bank.name)
    }

    if (!companyData) return null

    return <FormTab {...props} label="Basic Info">
        {!props.create && <BooleanInput source="active" />}
        <Grid container style={{ width: "100%" }}  >
            <TextInput label="Work Id" source="orgID" />
            <TextInput label="Mate Id" source="mateId" />
            <ReferenceInput perPage={1000} label="Site" source="siteId" reference="sites">
                {/*<SelectInput  validate={[required()]} optionText="name"  />*/}
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        </Grid>
        <Grid container style={{ width: "100%" }}  >
            <Grid item md={3}>
                <ReferenceInput perPage={1000} onChange={value => props.create && ChangeEarnings(value, dataProvider, form, form.getFieldState('employmentType').value, form.getFieldState('citizenship').value)} label="Position" source="positionId" reference="positions" >
                    {/*<SelectInput  validate={[required()]} optionText="name"  />*/}
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
            </Grid>
            <Grid item md={3}>
                <ReferenceInput perPage={1000} source="departmentId" reference="departments" >
                    {/*<SelectInput  validate={[required()]} optionText="name"  />*/}
                    <AutocompleteInput optionText="name" />

                </ReferenceInput>
            </Grid>
            <Grid item md={3}>
                <ReferenceInput perPage={1000} source="costCenterId" reference="costCenters" >
                    {/*<SelectInput  validate={[required()]} optionText="name"  />*/}
                    <AutocompleteInput optionText="name" />

                </ReferenceInput>
            </Grid>
        </Grid>
        <Grid container style={{ width: "100%" }}  >
            <Grid item md={1}>
                <AutocompleteInput clearable source="title" choices={[
                    { id: '', name: 'No Title' },
                    { id: 'Dr.', name: 'Dr.' },
                    { id: 'Prof.', name: 'Prof.' },
                    { id: 'Sr.', name: 'Sr.' },
                    { id: 'General', name: 'General' },
                ]} />

            </Grid>
            <Grid item md={3}>
                <TextInput source="name" validate={[required(), minLength(2), maxLength(15)]} />
            </Grid>
            <Grid item md={3}>
                <TextInput source="fatherName" validate={[required(), minLength(2), maxLength(15)]} />
            </Grid>
            <Grid item md={3}>
                <TextInput source="gFatherName" validate={[minLength(2), maxLength(15)]} />

            </Grid>
        </Grid>
        <Grid container style={{ width: "100%" }}  >

            <TextInput source="tinNo" validate={[maxLength(15)]} />
            <TextInput source="pensionNo" validate={[maxLength(15)]} />

        </Grid>
        <Grid container style={{ width: "100%" }}  >
            <SelectInput source="gender" choices={[
                { id: 'M', name: 'Male' },
                { id: 'F', name: 'Female' },
            ]} />
            <SelectInput onChange={ev => props.create && setDefaults(form.getFieldState('employmentType').value, form, companyData, ev.target.value, dataProvider)} defaultValue={"Ethiopian"} source="citizenship" choices={[
                { id: 'Ethiopian', name: 'Ethiopian' },
                { id: 'Non-Ethiopian', name: 'Non-Ethiopian' },
            ]} />
            <DateInput source="dateOfBirth" label="Date of Birth" options={{ format: 'DD/MM/YYYY', inputVariant: 'filled' }} />
        </Grid>
        <SelectInput onChange={ev => {
            props.create && setDefaults(ev.target.value, form, companyData, form.getFieldState('citizenship').value, dataProvider);
            if (ev.target.value === "Contract") {
                form.change("startDate", moment())
            }
        }} defaultValue={"FullTime"} source="employmentType" choices={[
            { id: 'FullTime', name: 'FullTime' },
            { id: 'Contract', name: 'Contract' },
            { id: 'PartTime', name: 'PartTime' },
            { id: 'InternOrFellow', name: 'InternOrFellow' },
            { id: 'Casual', name: 'Casual' },
        ]} />

        {/*<DateInput     source="startDate" label="Start date" options={{ format: 'DD/MM/YYYY', inputVariant:'filled'  }}  input={{onChange:ev=>console.log(ev)}}  />*/}
        <DateInput
            onChange={ev => {
                if (props.create) {
                    const settingDays = companyData.companySettings.find(s => s.name === 'Pension_StartDays')
                    const settingFullMonth = companyData.companySettings.find(s => s.name === 'Pension_FullMonth')
                    const StartAfterDays = settingDays ? +settingDays.value : 0
                    const StartFullMonth = settingFullMonth ? settingFullMonth.value === 'true' : true




                    const deductions = (form.getFieldState('deductions').value || []).slice(0)
                    const expenses = (form.getFieldState('expenses').value || []).slice(0)

                    const pension = deductions.find(d => d.deductionType === "EmpPension")
                    const compPension = expenses.find(d => d.type === "CompanyPension")



                    if (pension) {

                        // console.log(companyData)
                        pension.startDate = moment(ev).add(StartAfterDays, "day")
                        // if(StartFullMonth){
                        //     pension.startDate = pension.startDate.startOf('month')
                        // }
                        form.change('deductions', deductions)

                    }

                    if (compPension) {

                        // console.log(companyData)
                        compPension.startDate = moment(ev).add(StartAfterDays, "day")
                        // if(StartFullMonth){
                        //     compPension.startDate = pension.startDate.startOf('month')
                        // }
                        form.change('expenses', expenses)

                    }

                }





                //  form.change('contractEndDate', val)
            }
            } source="startDate"
            label="Start date" options={{ format: 'DD/MM/YYYY', inputVariant: 'filled' }} />
        <EndDateInput startDate={props.formData.startDate} employmentType={props.formData.employmentType} onDateChange={val => form.change('contractEndDate', val)} source="contractEndDate" label="End Date" options={{ format: 'DD/MM/YYYY' }} />

        <Grid container style={{ width: "100%" }}  >
            <NumberInput label={props.formData.employmentType === 'Casual' ? 'Daily Wadge' : 'Basic Salary'} source="basicSalary" validate={[required()]} />
            <SelectInput defaultValue={"ETB"} label='Currency' source="basicCurrency" choices={[
                { id: 'ETB', name: 'ETB' },
                { id: 'USD', name: 'USD' },
                { id: 'EUR', name: 'EUR' },
                { id: 'GBP', name: 'GPB' },
            ]} />
            {!props.create && <>
                <DateInput onChange={ev=>form.change('prevBasicSalary',props.formData.basicSalary)} source="newBasicSalaryStartDate" options={{ format: 'DD/MM/YYYY', inputVariant: 'filled' }} />
                {props.formData.newBasicSalaryStartDate && <>
                    <NumberInput  source="prevBasicSalary" />
                    <TextInput source="salaryAdjustmentRemarks" />
                </>}
            </>}

        </Grid>


        <Grid container style={{ width: "100%" }}  >
            <ReferenceInput filter={{ expand: 'bank' }} label="Bank" source="companyBankId" reference="companyBanks" onChange={setBank}>
                <SelectInput optionText="bank.name" />
            </ReferenceInput>
            <TextInput source="bankAccountNumber" />
            <TextInput label="Branch" source="bankBranch" />

            <TextInput source="bankAccountHolderName" />
        </Grid>

    </FormTab>


}
const EarningForm = ({ formData, scopedFormData, index, getSource, ...rest }) => {
    // console.log(scopeText)

    //const [data:templ]
    const { data: templateEarnings } = useQueryWithStore({
        type: 'getList',
        resource: 'earnings',
        payload: { filter: { filter: `Scope eq 'Template'` }, pagination: {}, sort: {} }
    });



    //console.log('index',scopedFormData)
    //console.log('rest',getSource)
    const form = useForm();
    if (!scopedFormData) {
        return null
    }
    return <Fragment>

        <SelectInput onChange={event => {
            const earning = templateEarnings.find(e => e.name === event.target.value)
            // console.log('earning',earning)
            if (earning) {
                form.change(getSource("name"), earning.name)
                form.change(getSource('taxOption'), earning.taxOption)
                form.change(getSource('limitByValue'), earning.limitByValue)
                form.change(getSource('valueType'), earning.valueType)
                form.change(getSource('basedOn'), earning.basedOn)
                form.change(getSource('currency'), earning.currency)
                //    form.change(getSource('earningType'), earning.name)
                form.change(getSource('templateId'), earning.id)

            }
        }}   {...rest} label="Earning Type" source={getSource("earningType")} optionValue='name' choices={templateEarnings} />
        {/*<ReferenceInput*/}
        {/*{...rest}*/}
        {/*source="type"*/}
        {/*reference="earnings"*/}
        {/*filter={{filter:`Scope eq 'Template'`}}*/}
        {/*>*/}

        {/*<SelectInput    optionText="name" />*/}
        {/*</ReferenceInput>*/}
        <TextInput   {...rest} label="Name" source={getSource('name')} />
        <SelectInput  {...rest} label="Tax Option" source={getSource("taxOption")} choices={[
            { id: 'NonTaxable', name: 'NonTaxable' },
            { id: 'Taxable', name: 'Taxable' },
            { id: 'TaxableWithLimits', name: 'TaxableWithLimits' },
        ]} />

        {scopedFormData.taxOption === 'TaxableWithLimits' &&

            <NumberInput  {...rest} label="Non Taxable Amount" source={getSource("limitByValue")} />
        }

        <SelectInput  {...rest} label="ValueType" source={getSource('valueType')} choices={[
            { id: 'Absolute', name: 'Absolute' },
            { id: 'Percentage', name: 'Percentage' },
        ]} />



        <NumberInput validate={[required()]} format={v => scopedFormData.valueType === "Percentage" ? (v * 1000000) / 10000 : v} parse={v => scopedFormData.valueType === "Percentage" ? v / 100 : v}  {...rest} label='Value' source={getSource("value")} />

    </Fragment>
}
const EmployeeEarning = props => {

    return <FormTab {...props} label="Earnings">
        <NumberInput source="basicSalary" />
        <ArrayInput label='Additional Earnings' source="earnings">
            <SimpleFormIterator  >
                <FormDataConsumer>
                    {formDataProps => (
                        <EarningForm {...formDataProps} />
                    )}
                </FormDataConsumer>
                {/*<TextInput label="Name" source="name" />*/}
                {/*<SelectInput label="Value Type"  source="valueType"  choices={[*/}
                {/*    { id: 'Absolute', name: 'Absolute' },*/}
                {/*    { id: 'Percentage', name: 'Percentage' },*/}
                {/*]}  />*/}

                {/*<NumberInput label="Value"  source="value" />*/}
            </SimpleFormIterator>
        </ArrayInput>


    </FormTab>


}
const deductionChoices = [
    { id: 'EmpPension', name: 'Pension (Employee)' },
    { id: 'PF', name: 'Provident Fund' },
    { id: 'loan', name: 'Repayment' },
    { id: 'Other', name: 'Other' },
]




const DeductionForm = ({ formData, getSource, source, scopedFormData, ...rest }) => {
    //console.log("indei",index)
    const form = useForm();

    const { data: templates } = useQueryWithStore({
        type: 'getList',
        resource: 'deductions',
        payload: { filter: { filter: `Scope eq 'Template'` }, pagination: {}, sort: {} }
    });

    // const { loaded, error, data: loans } = useQueryWithStore({
    //     type: 'getList',
    //     resource: 'loans',
    //     payload: {filter:{filter:`employeeId eq ${formData.employeeId} and LoanStatus eq 'Standing'`},pagination:{},sort:{}}
    // });

    if (!scopedFormData) {
        return null
    }

    return <Fragment>

        <SelectInput onChange={event => {
            const item = templates.find(e => e.id === event.target.value)
            form.change(getSource('name'), item.name)
            form.change(getSource('deductionType'), item.deductionType)
            form.change(getSource('valueType'), item.valueType)
            form.change(getSource('basedOn'), item.basedOn) 
            form.change(getSource('currency'), item.currency)
            // form.change('templateId',item.id)

        }}   {...rest} label="Template" source={getSource("templateId")} choices={templates} />



        <SelectInput source={getSource("deductionType")}  {...rest} onChange={value => {
            form.change(getSource('name'), deductionChoices.find(c => c.id === value.target.value).name)
            form.change(getSource('cash'), deductionChoices.find(c => c.id === value.target.value).id === 'loan')
        }
        } choices={deductionChoices} label='Deduction Type' />

        <TextInput {...rest} label='Name' source={getSource("name")} />

        {scopedFormData.deductionType !== 'IncomeTax' && (
            <Fragment>
                <SelectInput {...rest} label='Value Type' source={getSource("valueType")} choices={[
                    { id: 'Absolute', name: 'Absolute' },
                    { id: 'Percentage', name: 'Percentage' },
                ]} />

                {/*<NumberInput {...rest} source="value" />*/}

                <NumberInput format={v => scopedFormData.valueType === "Percentage" ? (v * 1000000) / 10000 : v} parse={v => scopedFormData.valueType === "Percentage" ? v / 100 : v}  {...rest} label="Value" source={getSource("value")} />
                <DateInputO source={getSource("startDate")} label="Start date" options={{ format: 'DD/MM/YYYY', inputVariant: 'filled', clearable: true }} />

                {(scopedFormData.deductionType === 'Other' || scopedFormData.deductionType === 'loan') &&
                    <BooleanInput  {...rest} label='Cash' source={getSource("cash")} />
                }
            </Fragment>
        )}




    </Fragment>
}


const EmployeeDeductions = props => {

    return <FormTab {...props} label="Deductions">

        <ArrayInput source="deductions">
            <SimpleFormIterator>
                <FormDataConsumer>
                    {formDataProps => (
                        <DeductionForm {...formDataProps} />
                    )}
                </FormDataConsumer>
                {/*<TextInput label="Name" source="name" />*/}
                {/*<SelectInput label="Value Type"  source="valueType"  choices={[*/}
                {/*    { id: 'Absolute', name: 'Absolute' },*/}
                {/*    { id: 'Percentage', name: 'Percentage' },*/}
                {/*]}  />*/}

                {/*<NumberInput  label="Value"  source="value" />*/}
            </SimpleFormIterator>
        </ArrayInput>


    </FormTab>


}

const expenseChoices = [
    { id: 'CompanyPension', name: 'Pension (Company)' },
    { id: 'CompanyPF', name: 'Provident Fund(Company)' },
    { id: 'Health Insurance', name: 'Health Insurance' },
    { id: 'Other', name: 'Other' },

]


const ExpenseForm = ({ formData, getSource, source, scopedFormData, ...rest }) => {
    //console.log("indei",index)
    const form = useForm();

    const { data: templates } = useQueryWithStore({
        type: 'getList',
        resource: 'expenses',
        payload: { filter: { filter: `Scope eq 'Template'` }, pagination: {}, sort: {} }
    });
    // const { loaded, error, data: loans } = useQueryWithStore({
    //     type: 'getList',
    //     resource: 'loans',
    //     payload: {filter:{filter:`employeeId eq ${formData.employeeId} and LoanStatus eq 'Standing'`},pagination:{},sort:{}}
    // });

    if (!scopedFormData) {
        return null
    }

    return <Fragment>
        <SelectInput onChange={event => {
            const item = templates.find(e => e.id === event.target.value)
            form.change(getSource('name'), item.name)
            form.change(getSource('type'), item.type)
            form.change(getSource('valueType'), item.valueType)
            form.change(getSource('basedOn'), item.basedOn)
            form.change(getSource('currency'), item.currency)
            // form.change('templateId',item.id)
        }}   {...rest} label="Template" source={getSource("templateId")} choices={templates} />



        <SelectInput {...rest} label='Expense Type' source={getSource("type")} onChange={value => form.change(getSource("name"), expenseChoices.find(c => c.id === value.target.value).name)} choices={expenseChoices} />
        <TextInput {...rest} label='Name' source={getSource("name")} />



        <SelectInput {...rest} label='Value Type' source={getSource("valueType")} choices={[
            { id: 'Absolute', name: 'Absolute' },
            { id: 'Percentage', name: 'Percentage' },
        ]} />

        {/*<NumberInput {...rest} source="value" />*/}

        <NumberInput format={v => scopedFormData.valueType === "Percentage" ? (v * 1000000) / 10000 : v} parse={v => scopedFormData.valueType === "Percentage" ? v / 100 : v}  {...rest} label="Value" source={getSource("value")} />


        <DateInputO source={getSource("startDate")} label="Start date" options={{ format: 'DD/MM/YYYY', inputVariant: 'filled', clearable: true }} />






    </Fragment>
}




const EmployeeExpenses = props => {

    return <FormTab {...props} label="Expenses">

        <ArrayInput source="expenses">
            <SimpleFormIterator>
                <FormDataConsumer>
                    {formDataProps => (
                        <ExpenseForm {...formDataProps} />
                    )}
                </FormDataConsumer>

            </SimpleFormIterator>
        </ArrayInput>
        {/*<ArrayInput  source="expenses">*/}
        {/*    <SimpleFormIterator>*/}
        {/*        <TextInput label="Name" source="name" />*/}
        {/*        <SelectInput label="Value Type"  source="valueType"  choices={[*/}
        {/*            { id: 'Absolute', name: 'Absolute' },*/}
        {/*            { id: 'Percentage', name: 'Percentage' },*/}
        {/*        ]}  />*/}

        {/*        <NumberInput label="Value"  source="value" />*/}
        {/*    </SimpleFormIterator>*/}
        {/*</ArrayInput>*/}


    </FormTab>


}



export const EmployeeCreate = props => {
    // const form = useForm();

    return (
        <Create {...props}>
            <TabbedForm redirect='list' >
                <FormDataConsumer>
                    {formDataProps => (
                        <EmployeeBasic create {...formDataProps} />
                    )}
                </FormDataConsumer>

                <AddressTab />
                {/*<EmployeeBasic/>*/}
                <EmployeeEarning />
                <EmployeeDeductions />
                <EmployeeExpenses />
                <FormDataConsumer>
                    {formDataProps => (
                        <MiscForm create {...formDataProps} />
                    )}
                </FormDataConsumer>

                {/*<FormTab    label="Basic">*/}
                {/*<ReferenceInput filter={{"expand":"earnings"}} label="Position" source="positionId" reference="positions" validate={[required()]}>*/}
                {/*<SelectInput   optionText="name"  />*/}
                {/*</ReferenceInput>*/}
                {/*<TextInput source="name" />*/}
                {/*<TextInput    source="fatherName" />*/}
                {/*<TextInput   source="gFatherName" />*/}



                {/*</FormTab>*/}
                {/*<FormTab  label="Advanced">*/}
                {/*<ArrayInput source="earnings">*/}
                {/*<SimpleFormIterator>*/}
                {/*<TextInput  label="Name" source="name" />*/}
                {/*</SimpleFormIterator>*/}
                {/*</ArrayInput>*/}
                {/*/!*<FormSpy*!/*/}
                {/*/!*subscription={{values: true }}*!/*/}
                {/*/!*onChange={props => {*!/*/}
                {/*/!*console.log('Form validity changed to', props.values)*!/*/}
                {/*/!*}}*!/*/}
                {/*/>*/}

                {/*</FormTab>*/}


                {/*<OnChange name="name">*/}
                {/*{value => {*/}
                {/*console.log(value)*/}
                {/*}}*/}
                {/*</OnChange>*/}

            </TabbedForm>
        </Create>
    )
};


const AddNewLoanButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: "/loans/create",
            search: `?employeeId=${record.id}&&source=1`,
            // search: `?source=${JSON.stringify({ scopeId: record.id,scope})}`,
        }}
        label="Add Loan"
    >
        <AddIcon />
    </Button>
);

const AddNewAccountButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: "/accounts/create",
            search: `?employeeId=${record.id}`,
            // search: `?source=${JSON.stringify({ scopeId: record.id,scope})}`,
        }}
        label="Add Account"
    >
        <AddIcon />
    </Button>
);

//CostAllocation
const AddCostAllocationButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: "/employeeCostCenterAllocations/create",
            search: `?employeeId=${record.id}`,
        }}
        label="Add Cost Allocation"
    >
        <AddIcon />
    </Button>
);


const EditLoanButton = ({ type, record, id }) => (
    <Button
        component={Link}
        to={{
            pathname: `/loans/${record.id}`,
            search: `?employeeId=${id}&&source=1`,
            //search: `?source=${JSON.stringify({ scopeId: record.id,scope})}`,
        }}
        label="Edit"
    >
        <EditIcon />
    </Button>
);

const EmployeeBasicShow = ({ source, record = {} }) => {
    if (!record) {
        return null;
    }
    return <div>
        <Grid container direction="column">
            <Grid item container spacing={10}>
                <Grid item><CaptionedField label="Work Id" value={record.orgID} style="text" /></Grid>
                {record.mateId && <Grid item><CaptionedField label="Mate Id" value={record.mateId} style="text" /></Grid>}
                {record.address && <Grid item><CaptionedField label="Email" value={record.address.email} style="text" /></Grid>}
            </Grid>
            <Typography size="xl"  >
                {record.title}  {record.name}  {record.fatherName}  {record.gFatherName}
            </Typography>

            <Grid item container spacing={10} >
                <Grid item><CaptionedField defaultCurrency={record.defaultCurrency} label="Basic Salary" value={record.basicSalary} /></Grid>
                <Grid item><CaptionedField label="Employment Type" value={record.employmentType} style="text" /></Grid>
                <Grid item><CaptionedField label="Citizenship" value={record.citizenship} style="text" /></Grid>
            </Grid>
            <Grid item container spacing={10} >
                <Grid item><CaptionedField label="Start Date" style="text" value={record.startDate ? moment(record.startDate).format("MMM DD YYYY") : ''} /></Grid>
                {record.contractEndDate && moment(record.contractEndDate) > moment("1970/1/1") && <Grid item><CaptionedField label="Contract End Date" style="text"
                    value={moment(record.contractEndDate).format("MMM DD YYYY")} /></Grid>
                }
            </Grid>
            <Grid item container spacing={10} >
                <Grid item><CaptionedField label="Tin No" style="text" value={record.tinNo} /></Grid>
                <Grid item><CaptionedField label="Pension No" style="text" value={record.pensionNo} /></Grid>
            </Grid>

            <Grid item container spacing={10} >
                {/*<Grid item><CaptionedField label="Bank"  style="text" value={record.bank.name}/></Grid>*/}
                <Grid item><CaptionedField label="Bank Account No" style="text" value={record.bankAccountNumber} /></Grid>
            </Grid>

        </Grid>
    </div>
}

export const EmployeeShow = props => (

    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Basic Info">
                <EmployeeBasicShow />
                {/* <TextField  label='Work Id'  source="orgID" />*/}

                {/* <TextField source="name" />*/}
                {/*<TextField  source="fatherName" />*/}
                {/* <TextField  source="gFatherName" />*/}

                {/* <TextField source="basicSalary" />*/}
                {/* <TextField  label='Email' type='email' source="address.email" />*/}
                {/* <TextField  label='Mate Id' type='email' source="mateId" />*/}
                {/* <TextField   source="tinNo" />*/}
                {/* <TextField source="pensionNo" />*/}

                {/* <TextField source="employmentType" />*/}

                {/* <DateField source="startDate" />*/}
                {/* <ConditionalField source="contractEndDate" />*/}

                {/*<DateField source="contractEndDate" />*/}


            </Tab>
            <AddressTabShow />
            {/*adjustment tab*/}
            <Tab label="Adjustments" path="employeeAdjustments">
                <ReferenceManyField
                    addLabel={false}
                    reference="employeeAdjustments"
                    target="employees"
                >
                    <Datagrid>
                        <DateField source="effectiveDate" />
                        <TextField source="adjustmentType" />
                        <TextField source="name" />
                        <AdjustmentValueField source="value" />
                        <AdjustmentValueField source="oldValue" />
                        <TextField source="status" />
                        <ApproveButton />
                        <PendingEditButtonWithEmployeeId employeeId={props.id} />
                    </Datagrid>
                </ReferenceManyField>
                <AddNewAdjustmentButton />
            </Tab>
            {/*adjustment tab*/}
            <Tab label="Additional Earnings" path="earnings">

                <ReferenceManyField
                    addLabel={false}
                    reference="earnings"
                    target="employees"


                >
                    <Datagrid>
                        <TextField source="name" />
                        <TextField source="valueType" />
                        <PercentField source="value" />
                        <EditXButton type="earnings" scope={2} id={props.id} />
                        <DeleteButton basePath={`/employees/${props.id}/show/earnings`} />

                    </Datagrid>
                </ReferenceManyField>
                <AddItemButton label={"Add an Earning"} path="/earnings/create" scope={2} />


            </Tab>
            <Tab label="Deductions" path="deductions">
                <ReferenceManyField
                    addLabel={false}
                    reference="deductions"
                    target="employees"

                >
                    <Datagrid >
                        <TextField source="name" />
                        <TextField source="valueType" />
                        <PercentField source="value" />
                        <EditXButton type="deductions" scope={2} id={props.id} />
                        <DeleteButton basePath={`/employees/${props.id}/show/deductions`} />
                    </Datagrid>
                </ReferenceManyField>
                <AddNewDeductionButton scope={2} />

            </Tab>
            <Tab label="Expenses" path="expenses">
                <ReferenceManyField
                    addLabel={false}
                    reference="expenses"
                    target="employees"
                >
                    <Datagrid >
                        <TextField source="name" />
                        <TextField source="valueType" />
                        <PercentField source="value" />
                        <EditXButton type="expenses" scope={2} id={props.id} />
                        <DeleteButton basePath={`/employees/${props.id}/show/expenses`} />
                    </Datagrid>
                </ReferenceManyField>
                <AddNewExpenseButton scope={2} />

            </Tab>
            <Tab label="Loans" path="loans">
                <ReferenceManyField
                    addLabel={false}
                    reference="loans"
                    target="employees"
                >
                    <Datagrid >
                        <TextField source="name" />
                        <TextField source="type" />
                        <NumberField source="requestedAmount" />
                        {/*<EditButton/> because of redirect*/}
                        <ShowButton />
                        <EditLoanButton id={props.id} />

                    </Datagrid>
                </ReferenceManyField>
                <AddNewLoanButton />

            </Tab>
            <Tab label="Accounts" path="accounts">
                <ReferenceManyField
                    addLabel={false}
                    reference="accounts"
                    target="employees"
                >
                    <Datagrid >
                        <TextField source="code" />
                        <TextField source="name" />
                        <TextField source="category" />
                        <TextField source="templateType" />
                        {/*<TemplateField/>*/}
                        <ShowButton />
                        <EditButton />


                    </Datagrid>
                </ReferenceManyField>
                <AddNewAccountButton />

            </Tab>
            <Tab label="Cost Center Allocations" path="employeeCostCenterAllocations">
                <ReferenceManyField
                    addLabel={false}
                    reference="employeeCostCenterAllocations"
                    target="employees"  
                >
                    <Datagrid>
                    
                        <ReferenceField label="Cost Center" source="costCenterId" reference="costCenters">
                            <TextField source="name" />
                        </ReferenceField>
                        <PercentField source="allocationPercentage" />
                        <TextField source="remarks" />
                   
                        <EditButtonWithEmployeeId type="employeeCostCenterAllocations"  employeeId={props.id} />

                    </Datagrid>
                </ReferenceManyField>
                
                <TotalAllocationPercentage employeeId={props.id} />
                <AddCostAllocationButton />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const TemplateField = props => {
    const category = props.record['category']

    return <ReferenceField source="templateId" reference={category === 'loans' ? "loanTemplates" : category} {...props}>
        <TextField source="name" />
    </ReferenceField>
}

const TotalAllocationPercentage = ({ employeeId }) => {
    const { loaded, error, data: allocations} = useQueryWithStore({
        type: 'getList',
        resource: 'employeeCostCenterAllocations',
        payload: {filter:{filter:`employeeId eq ${employeeId}`}}
    });     

    const { data: costCenters } = useQueryWithStore({
        type: 'getList',
        resource: 'costCenters',
        payload: {filter:{},pagination:{},sort:{}}
    });

    // console.log(costCenters, "costCenters", allocations, "allocations")

    if (!loaded || !costCenters) return null;

    const total = allocations ? allocations.reduce((sum, allocation) => 
        sum + (allocation.allocationPercentage || 0), 0) : 0;

    const chartData = allocations ? allocations.map(allocation => {
        const costCenter = costCenters.find(cc => cc.id === allocation.costCenterId);
        return {
            name: costCenter ? costCenter.name : 'Unknown',
            value: allocation.allocationPercentage,
            percentage: (allocation.allocationPercentage * 100).toFixed(1)
        };
    }) : [];

    if (total < 1) {
        chartData.push({
            name: 'Unallocated',
            value: 1 - total,
            percentage: ((1 - total) * 100).toFixed(1)
        });
    }

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D'];

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ 
                    backgroundColor: '#fff', 
                    padding: '10px', 
                    border: '1px solid #ccc',
                    borderRadius: '4px'
                }}>
                    <p>{`${payload[0].name}: ${payload[0].payload.percentage}%`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <Typography 
                variant="h5" 
                style={{ 
                    color: Math.abs(total - 1) < 0.01 ? '#4caf50' : '#f44336',
                    fontWeight: 'bold',
                    marginBottom: '16px'
                }}
            >
                Total Allocation: {(total*100).toFixed(2)}%
            </Typography>
            
            <div style={{ width: '300px', height: '300px' }}>
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            data={chartData}
                            innerRadius={90}
                            outerRadius={150}
                            paddingAngle={1}
                            dataKey="value"
                            nameKey="name"
                            label={({ name, percentage }) => `${name}: ${percentage}%`}
                        >
                            {chartData.map((entry, index) => (
                                <Cell 
                                    key={`cell-${index}`} 
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </Box>
    );
};

const AddNewAdjustmentButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: "/employeeAdjustments/create",
            search: `?employeeId=${record.id}`,
        }}
        label="Add Adjustment"
    >
        <AddIcon />
    </Button>
);

const PendingEditButtonWithEmployeeId = ({ record, employeeId, ...rest }) => 
    record && record.status === 'Pending' ? (
        <EditButtonWithEmployeeId 
            record={record} 
            type="employeeAdjustments" 
            employeeId={employeeId} 
            {...rest} 
        />
    ) : null;
