import ExcelExport from "@progress/kendo-react-excel-export/dist/npm/ExcelExport";
import React from "react";
import ExcelExportColumn from "@progress/kendo-react-excel-export/dist/es/ExcelExportColumn";

// function otherEarnings(templateEarnings)
// {
//   if(templateEarnings && templateEarnings.length)
//   {
//       return templateEarnings.map(e=>({
//           field:e.name.replace(/\s+/g, '').toLowerCase(),
//           title:e.name,
//           show: true,
//           width:120
//       })).concat([
//           {
//
//               field:"otherEarnings",
//               title:"Other Monthly fixed earnings",
//               show: true,
//               width:120
//           }
//       ])
//   }
//
// }
//
// function otherDeductions(template)
// {
//     if(template && template.length)
//     {
//         return template.map(e=>({
//             field:e.name.replace(/\s+/g, '').toLowerCase(),
//             title:e.name,
//             show: true,
//             width:120
//         })).concat([
//             {
//
//                 field:"otherDeductions",
//                 title:"Other Monthly fixed deductions",
//                 show: true,
//                 width:120
//             }
//         ])
//     }
//
// }

function templateFields(template,type)
{
    if(template && template.length)
    {
        return template.map(e=>({
            field:e.name.replace(/\s+/g, '').toLowerCase(),
            title:e.name,
            format: "{0:n2}",
            show: true,
            width:120
        })).concat( template.filter(t=>t.showOriginal).map(e=>({
            field:'full'+e.name.replace(/\s+/g, '').toLowerCase(),
            title:'Full '+e.name,
            format: "{0:n2}",
            show: false,
            width:120
        }))).concat( template.filter(t=>t.showOriginal).map(e=>({
            field:e.name.replace(/\s+/g, '').toLowerCase()+'difference',
            title:e.name+' Difference',
            format: "{0:n2}",
            show: false,
            width:120
        }))).concat([
            {

                field:`other${type}`,
                title:`Other Monthly fixed ${type}`,
                format: "{0:n2}",
                show: true,
                width:120
            }
        ])
    }
    else return []

}


const columns = (template,companySettings) => [
    {
        title: 'SN',
        field: 'sn',
        show: true,
        width:80,

    },
    {
        title: 'System Id',
        field: 'employeeId',
        show: false,
        width:120
    },
    {
        title: 'Work Id',
        field: 'orgID',
        show: false,
        width:120
    },
    {
        title: 'National Id',
        field: 'nationalID',
        show: false,
        width:120
    },
    {
        title: 'Tax Region',
        field: 'taxRegion',
        show: false,
        width:120
    },
    {
        title: 'Tin Number',
        field: 'tinNo',
        show: false,
        width:120
    },


    {
        title: 'Position Name',
        field: 'positionName',
        show: false,
        width:120


    },
    {
        title: 'Department Name',
        field: 'departmentName',
        show: false,
        width:120

    },
    ...extraColumns(companySettings),
    {
        title: 'Program',
        field: 'program',
        show: false,
        width:120
    },
    {
        title: 'Function',
        field: 'function',
        show: false,
        width:120

    },
    {
        title: 'Sub Function',
        field: 'subFunction',
        show: false,
        width:120

    },
    {
        title: 'Grade',
        field: 'grade',
        show: false,
        width:120

    },
    {
        title: 'Step',
        field: 'step',
        show: false,
        width:120
    },

    {
        title: 'Bank',
        field: 'bank',
        show: false,
        width:120

    },
    {
        title: 'Swift Code',
        field: 'swiftCode',
        show: false,
        width:120

    },
    {
        title: 'Bank Account Number',
        field: 'bankAccountNumber',
        show: false,
        width:120

    },

    {
        title: 'Cost Center',
        field: 'costCenterCode',
        show: false,
        width:100

    },
    {
        title: 'Employment Type',
        field: 'employmentType',
        show: false,
        width:120

    },
    {
        title: 'Site Name',
        field: 'siteName',
        show: false,
        width:120


    },
    {
        title: 'Full Name',
        field: '_fullName',
        filter: 'text',
        show: true,
        width:150
    },
    {
        title: 'Start Date',
        field: 'formatedStartDate',
        show: false,
        width:120


    },
    {
        title: 'Currency',
        field: 'basicCurrency',
        show: false,
        width:80
    },
    {
        title: 'Basic Salary (in Basic Currency)',
        field: 'salaryInBasicCurrency',
        format: "{0:n2}",
        show: false,
        filter: 'numeric',
        width:120
    },

    {
        title: 'Full Basic Salary',
        field:'fullBasicSalary',
        format: "{0:n2}",
        show: false,
        filter: 'numeric',
        width:120
    },
    {
        title: 'Basic Salary Difference',
        field:'basicSalaryDifference',
        format: "{0:n2}",
        show: false,
        filter: 'numeric',
        width:120
    },
    {
        title: 'Basic Salary Adjustment',
        field:'basicSalaryAdjustment',
        format: "{0:n2}",
        show: false,
        filter: 'numeric',
        width:120
    },
    //currency rate
    {
        title: 'Currency Rate',
        field:'currencyRate',
        format: "{0:n2}",
        show: false,
        filter: 'numeric',
        width:80
    },
    {
        title: 'Basic Salary',
        field:'basicSalary',
        format: "{0:n2}",
        show: true,
        filter: 'numeric',
        width:120
    },

    // {
    //     title: 'Absent Days',
    //     field: 'absentDays',
    //     show: false,
    //     width:80
    //
    // },
    // {
    //     title: 'Leave Days',
    //     field: 'leaveDays',
    //     show: false,
    //     width:80
    //
    // },
    ...templateFields(template.templateEarnings,"Earnings"),
    {
        title: 'Total monthly fixed additional earnings',
        field: 'additionalFixedEarnings',
        show: true,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'Fixed Earnings',
        field: 'fixedGrossIncome',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Ot Normal',
        field: 'otNormal',
        show: false,
        format: "{0:n2}",
        width:100

    },

    {
        title: 'Ot Rest',
        field: 'otRest',
        show: false,
        format: "{0:n2}",
        width:100

    },

    {
        title: 'Ot HollyDay',
        field: 'otHollyDay',
        show: false,
        format: "{0:n2}",
        width:100

    },
    {
        title: 'Ot Night',
        field: 'otNight',
        show: false,
        format: "{0:n2}",
        width:100

    },
    {
        title: 'Ot Other',
        field: 'otOther',
        show: false,
        format: "{0:n2}",
        width:100

    },


    {
        title: 'Overtime Hours',
        field: 'OTTotalPayHours',
        show: false,
        format: "{0:n2}",
        width:100

    },

    {
        title: 'Overtime Total',
        field: 'overtimeTotal',
        show: true,
        format: "{0:n2}",
        width:120

    },


    {
        title: 'Shift Allowance 10%',
        field: 'shiftAllowance10',
        show: false,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'Shift Allowance 15%',
        field: 'shiftAllowance15',
        show: false,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'Shift Allowance 20%',
        field: 'shiftAllowance20',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Shift Allowance 25%',
        field: 'shiftAllowance25',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Shift Allowance Hours',
        field: 'shiftAllowanceHours',
        show: false,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'Shift Allowance Total',
        field: 'shiftAllowance',
        show: false,
        format: "{0:n2}",
        width:120

    },




    {
        title: 'On Call Allowance 10%',
        field: 'onCallAllowance10',
        show: false,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'On Call Allowance 15%',
        field: 'onCallAllowance15',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'On Call Allowance Hours',
        field: 'onCallAllowanceHours',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'On Call Allowance Total',
        field: 'onCallAllowance',
        show: false,
        format: "{0:n2}",
        width:120

    },




    {
        title: 'Service Charge',
        field: 'serviceCharge',
        show: false,
        format: "{0:n2}",
        width:120

    },



    {
        title: 'Back Pay',
        field: 'backPay',
        show: false,
        format: "{0:n2}",
        width:100

    },
    {
        title: 'Bonus Factor',
        field: 'bonusFactor',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Bonus Months',
        field: 'monthsInBonusYear',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Annual Bonus',
        field: 'annualBonus',
        show: false,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'Commission',
        field: 'commission',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Other Extra Earnings',
        field: 'otherExtraEarnings',
        show: false,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'Extra Earnings Total',
        field: 'bonusesTotal',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Total Income',
        field: 'totalIncome',
        show: true,
        format: "{0:n2}",
        width:120

    },
    // {
    //     title: 'Additional Earnings Total',
    //     field: 'additionalEarningsTotal',
    //     show: true,
    //     format: "{0:n2}",
    //     width:120
    //
    // },
    {
        title: 'Leave Days',
        field: 'leaveDays',
        show: false,
        format: "{0}",
        width:80

    },

    {
        title: 'Absent Days',
        field: 'absentDays',
        show: false,
        format: "{0}",
        width:80

    },

    {
        title: 'Missed Hours',
        field: 'missedHours',
        show: false,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'Basic Absent Deduction',
        field: 'basicAbsentDeduction',
        show: true,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'Additional Earnings Absent Deduction',
        field: 'additionalEarningsAbsentDeduction',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Total Absent Deduction',
        field: 'absenceDeduction',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Service Charge Deductions',
        field: 'serviceChargeDeduction',
        show: false,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'Other Deductions From Earnings',
        field: 'otherEarningDeduction',
        show: false,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'Gross Income',
        field: 'grossIncome',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Non Taxable Income',
        field: 'nonTaxableIncome',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Taxable Income',
        field: 'taxableIncome',
        show: true,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Income Tax',
        field: 'incomeTax',
        show: true,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Full Employee Pension',
        field: 'empPensionFull',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Employee Pension',
        field: 'empPension',
        show: true,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Employee Pension Difference',
        field: 'empPensionDifference',
        show: false,
        format: "{0:n2}",
        width:120

    },

    // {
    //     title: 'Company Pension',
    //     field: 'companyPension',
    //     show: true,
    //     format: "{0:n2}",
    //     width:120
    //
    // },
    {
        title: 'Provident Fund',
        field: 'pf',
        show: false,
        format: "{0:n2}",
        width:120

    },
    // {
    //     title: 'Company PF',
    //     field: 'companyPf',
    //     show: true,
    //     format: "{0:n2}",
    //     width:120
    //
    // },
    ...templateFields(template.templateDeductions,"Deductions"),
    {
        title: 'Fixed Deductions Totals And IncomeTax',
        field: 'fixedDeductionsTotalsAndIncomeTax',
        show: true,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Other Payroll Deductions',
        field: 'payrollDeductionTotal',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Full Company Pension',
        field: 'companyPensionFull',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Company Pension',
        field: 'companyPension',
        show: true,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Company Pension Difference',
        field: 'companyPensionDifference',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Total Pension Cont.',
        field: 'totalPension',
        show: false,
        format: "{0:n2}",
        width:120
    },
    {
        title: 'Company Provident Fund',
        field: 'companyPf',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Total Provident Fund',
        field: 'totalPf',
        show: false,
        format: "{0:n2}",
        width:120

    },
    ...templateFields(template.templateExpenses,"Expenses"),

    {
        title: 'Payback Taxes',
        field: 'paybackTax',
        format: "{0:n2}",
        show: false,
        width:120

    },
    {
        title: 'Other Cash Deductions',
        field: 'nonTaxDeductions',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Additional Income Tax',
        field: 'additionalIncomeTax',
        show: false,
        format: "{0:n2}",
        width:120

    },




    ...templateFields(template.templateLoans,"Settlements"),

    {
        title: 'Pay',
        field: 'netPay',
        show: false,
        format: "{0:n2}",
        width:120

    },

    {
        title: 'Total Settlements',
        field: 'payrollDeductionTotalCash',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Net Annual Bonus',
        field: 'netAnnualBonus',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Employer Covered Tax',
        field: 'coveredIncomeTax',
        show: false,
        format: "{0:n2}",
        width:120

    },
    {
        title: 'Net Pay',
        field: 'cash',
        format: "{0:n2}",
        show: true,
        width:130

    },
    {
        title: 'Sign',
        field: 'sign',
        // format: "{0:n2}",
        show: false,
        width:100

    },
    {
        title: 'Note',
        field: 'note',
        show: false,
        width:120
    }
];

function extraColumns(companySettings) {
    // console.log({companySettings})
    if(companySettings&&companySettings.enableExtraColumns&&(companySettings.enableExtraColumns.value==='yes'||companySettings.enableExtraColumns.value==='true')) {
        return [
            {
                title: 'Pension Number',
                field: 'pensionNo',
                // format: "{0:n2}",
                show: false,
                width:100

            },
            {
                title: 'Gender',
                field: 'gender',
                // format: "{0:n2}",
                show: false,
                width:100

            },
            {
                title: 'Date of Birth',
                field: 'dateOfBirth',
                // format: "{0:n2}",
                show: false,
                width:100

            },
            // {
            //     title: 'Employment Date',
            //     field: 'startDate',
            //     // format: "{0:n2}",
            //     show: false,
            //     width:100
            //
            // },
            {
                title: 'Email Address',
                field: 'email',
                // format: "{0:n2}",
                show: false,
                width:100

            },
            {
                title: 'Citizenship',
                field: 'citizenship',
                // format: "{0:n2}",
                show: false,
                width:100

            },
        ]
    }
    return []

}

export default columns;
