import React from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import CardContent from "@material-ui/core/CardContent";
import BackPayItem from "./backPayItem";
import BonusMonths from "./bonusMonths";
import { earningTypes } from "../../Helpers/typeList";
import { useQueryWithStore } from 'react-admin';
import { convertCurrency } from '../../Helpers/salaryCalculator';

const BonusItem = props => {
   // console.log('ott',props)
    const [type, setType] = React.useState(props.value.type||'other');
    const [name, setName] = React.useState(props.value.name||'Other');
    const [value, setValue] = React.useState(+props.value.value||0);
    // const [leaveDays, setLeaveDays] = React.useState(+props.value.value||0);
    const [extraData, setExtraData] = React.useState(props.value.extraData||{});
    const [taxOption, setTaxOption] = React.useState(props.value.taxOption || 'Taxable');
    

    const { data: positions } = useQueryWithStore({
        type: 'getList',
        resource: 'positions',
        payload: {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'ASC' },
            filter: {}
        }
    });

    const getActingAllowance = (position, actingDays) => {
        const positionData = positions.find(p => p.name === position)
        if(!positionData) return 0
        const fullBasicSalary = convertCurrency(positionData.basicSalary, positionData.basicCurrency, props.usedCurrencyRates)
        // console.log({fullBasicSalary, actingDays, totalWorkDays: props.payroll.totalWorkDays})
        return fullBasicSalary * actingDays / props.payroll.totalDaysInPayPeriod
    }


    const allowanceRates = [
        {value: 0.1, name: '10%', appliesTo:['shiftAllowance','onCallAllowance']},
        {value: 0.15, name: '15%',appliesTo:['shiftAllowance','onCallAllowance']},
        {value: 0.20, name: '20%',appliesTo:['shiftAllowance']},
        {value: 0.25, name: '25%',appliesTo:['shiftAllowance']},
    ]
    return (
        <Grid container>
            <Grid item xs={3}>
                <FormControl margin='dense' fullWidth  >
                    <InputLabel>Earning Type</InputLabel>
                    <Select
                        value={type}
                        onChange={ev=> {
                            const selectedType = earningTypes.find(t=>t.id===ev.target.value)
                            setType(ev.target.value)
                            setName(selectedType.name)
                            setTaxOption(selectedType.taxOption)
                            !!selectedType.value&&setValue(selectedType.value)

                            props.onChange({type: ev.target.value, name:selectedType.name, taxOption:selectedType.taxOption,value:selectedType.value||value, extraData})                        }}
                    >
                        {earningTypes.map(to=><MenuItem key={to.id} value={to.id}>{to.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>


              <Grid item xs={3}>



                    <TextField
                        onChange={ev => {
                            setName(ev.target.value)
                            props.onChange({type, name: ev.target.value,taxOption,value, extraData})

                        }}
                        value={name}
                        margin="dense"
                        label="Title"
                        fullWidth
                    />
            </Grid>
            {type==='backPay'&&<Grid item xs={3}>
                <BackPayItem payroll={props.payroll} extraData={extraData}  onChange={d => {
                    // console.log({d})
                    setExtraData(d)
                    props.onChange({type, name, taxOption, value, extraData:d})
                }}/>
            </Grid>}
            {type==='bonus'&&<Grid item xs={3}>
                <BonusMonths payroll={props.payroll} extraData={extraData}  onChange={d => {
                    // console.log({d})
                    setExtraData(d)
                    props.onChange({type, name, taxOption, value, extraData:d})
                }}/>
            </Grid>}
            {type === 'actingAllowance' && <><Grid item xs={3}>
                <FormControl margin='dense' fullWidth  >
                    <InputLabel>Acting Position</InputLabel>
              <Select   
                    value={extraData.position}
                    label="Acting Position"
                    fullWidth
                    margin="dense"
                    onChange={ev => {
                        setExtraData({...extraData, position: ev.target.value})
                        props.onChange({type, name, taxOption, value, extraData: {...extraData, position: ev.target.value}})
                    }}
              >
                {(positions||[]).map(p=> <MenuItem key={p.id} value={p.name}>{p.name} ({p.basicSalary})</MenuItem>)}
              </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <TextField
                        onChange={ev => {
                            const val = getActingAllowance(extraData.position, +ev.target.value)
                            setValue(val)
                        setExtraData({...extraData, actingDays: +ev.target.value})
                        props.onChange({type, name, taxOption, value, extraData: {...extraData, actingDays: +ev.target.value}})
                    }}
                    value={extraData.actingDays || 0}
                    type="number"
                    autoFocus
                    margin="dense"
                    label="Acting Days"
                    fullWidth
                />
            </Grid>
            
            
            </>}
            {type==='leave'&&<Grid item xs={3}>
                <TextField
                    onChange={ev => {
                        // const v = type.toLowerCase().includes('deduction')?-Math.abs(+ev.target.value):+ev.target.value
                       const v = props.payroll.fullBasicSalary/26 * +ev.target.value

                        setValue(v)
                        setName()
                        setExtraData({...extraData,leaveDays:+ev.target.value})
                        props.onChange({type, name,taxOption,value:v,extraData:{...extraData,leaveDays:+ev.target.value}})
                    }}
                    value={extraData.leaveDays}
                    type="number"
                    autoFocus
                    margin="dense"
                    label="Leave Days"
                    fullWidth
                />
            </Grid>}
            {type!=='backPay'&&type!=='bonus'&&<Grid item xs={3}>
                <FormControl margin='dense' fullWidth>
                    <InputLabel>Tax Option</InputLabel>
                    <Select
                        value={taxOption}
                        onChange={ev => {
                            setTaxOption(ev.target.value)
                            props.onChange({type, name, taxOption: ev.target.value, value, extraData})
                        }}
                    >
                        {[
                            {id: 'NonTaxable', name: 'NonTaxable'},
                            {id: 'Taxable', name: 'Taxable'},
                            {id: 'TaxableWithLimits', name: 'TaxableWithLimits'},
                            {id: 'TaxableWithPayback', name: 'Taxable But Payback'},
                            { id: 'TaxCovered', name: 'Tax Covered By Employer (Add to Net)' },
                        ].map(to => <MenuItem key={to.id} value={to.id}>{to.name}</MenuItem>)}
                    </Select>
                </FormControl>


            </Grid>}


            {(type==='shiftAllowance'||type==='onCallAllowance')&&<Grid item xs={2}>
                <FormControl margin='dense' fullWidth>
                    <InputLabel>Allowance Rate</InputLabel>
                    <Select
                    onChange={ev => {
                        // const v = type.toLowerCase().includes('deduction')?-Math.abs(+ev.target.value):+ev.target.value
                        const v = (props.payroll.fullBasicSalary)/(props.payroll.monthlyOTHours)*ev.target.value * extraData.shiftHour
                        const selectedAllowanceRate = allowanceRates.find(s=>s.value===ev.target.value)
                        const selectedType = earningTypes.find(t=>t.id===type)||{name:'Allowance'}
                        setName(selectedAllowanceRate?selectedAllowanceRate.name+" "+selectedType.name:name);
                        setValue(v)
                        setExtraData({...extraData,shiftRate:+ev.target.value})
                        props.onChange({type, name:selectedAllowanceRate?selectedAllowanceRate.name+" "+selectedType.name:name,taxOption,value:v,extraData:{...extraData,shiftRate:+ev.target.value}})
                    }}
                    value={extraData.shiftRate}
                    autoFocus
                    margin="dense"
                    fullWidth
                >
                        {allowanceRates.filter(rate=>rate.appliesTo.includes(type)).map(rate => <MenuItem key={rate.value} value={rate.value}>{rate.name}</MenuItem>)}
                    </Select>
                </FormControl>

            </Grid>}
            {(type==='shiftAllowance'||type==='onCallAllowance')&&<Grid item xs={2}>

                <TextField
                    onChange={ev => {
                        // const v = type.toLowerCase().includes('deduction')?-Math.abs(+ev.target.value):+ev.target.value
                        const v = props.payroll.fullBasicSalary/(props.payroll.monthlyOTHours)*ev.target.value * extraData.shiftRate
                        setValue(v)
                        setExtraData({...extraData,shiftHour:+ev.target.value})
                        props.onChange({type, name,taxOption,value:v,extraData:{...extraData,shiftHour:+ev.target.value}})
                    }}
                    value={extraData.shiftHour}
                    type="number"
                    autoFocus
                    margin="dense"
                    label="Shift Hour"
                    fullWidth
                />

            </Grid>}

            <Grid item xs={2}>
                <TextField
                    onChange={ev => {
                        const v = type.toLowerCase().includes('deduction')?-Math.abs(+ev.target.value):+ev.target.value
                        setValue(v)
                        props.onChange({type, name,taxOption,value:v,extraData})
                    }}
                    value={value}
                    type="number"
                    autoFocus
                    margin="dense"
                    label="Value"
                    fullWidth
                />

            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={props.onRemove} aria-label="delete" >
                    <DeleteIcon fontSize="large" />
                </IconButton>



            </Grid>

        </Grid>
    );
};

export default BonusItem;
