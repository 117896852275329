import React from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import CardContent from "@material-ui/core/CardContent";
import DistEarningsAppliesToPopover from "./distEarningsAppliesToPopover";
import {PlayArrow, RefreshOutlined} from "@material-ui/icons";
import moment from "moment";
import Zemen from "../../UIComponents/ZemenCalendar/zemen";
import {delay} from "../../Helpers/utilityFunctions";

const DistEarningItem = props => {
    // console.log('ott',props)
    // const [type, setType] = React.useState(props.value.type ||'Service Charge');
    const [taxOption, setTaxOption] = React.useState(props.value.taxOption ||'Taxable');
    const [type, setType] = React.useState(props.value.type ||'serviceCharge');
    const [name, setName] = React.useState(props.value.name ||'*Service Charge');
    const [totalTime, setTotalTime] = React.useState(+props.value.totalTime || 0);
    const [baseAmount, setBaseAmount] = React.useState(+props.value.baseAmount||0);
    const [appliesTo, setAppliesTo] = React.useState(props.value.appliesTo||{groups:[],endDate:null,emps:[]});
    const [basedOn, setBasedOn] = React.useState(props.value.basedOn||'serviceHours');
    const [applied, setApplied] = React.useState(props.value.applied || false);
    const [category, setCategory] = React.useState(props.value.category || 'earnings');
    const earningTypes =[
        { id: 'serviceCharge', name: '*Service Charge', taxOption:'Taxable' },
        { id: 'bonus', name: 'Bonus', taxOption:'Taxable' },
        { id: 'commission', name: 'Commission', taxOption:'Taxable' },
    ]
    const categories = [{
        id: 'earnings',
        name: 'Earnings'
    },
    {
        id: 'deductions',
        name: 'Deductions'
        },
        {
            id: 'expenses',
            name: 'Expenses'
        }
    ]
    
    const setGroups = groups => {
        if(groups.some(g=>!g)) {
            groups = []
        }

        const payrolls  = props.payrollRun.payrolls.filter(p=>!p.excludeFromDistEarnings&&(!groups.length||groups.map(g=>g.toLowerCase()).includes(p.siteName.toLowerCase()))&&(!appliesTo.endDate||moment(p.startDate)<=moment(appliesTo.endDate)))

        const app = {
            groups,
            endDate: appliesTo.endDate,
            emps:payrolls.map(p=>p.employeeId)}
        setAppliesTo(
            app
            )
        const newName = `*${type} ${groups.join(",")} ${appliesTo.endDate?moment(appliesTo.endDate).add(-15,"days").format("MMM"):''}`

        const totalTimeCalculated  = payrolls.reduce((sum,p)=>{

               const daysWorked =  sum + p.distEarningsOnActualDate?moment(appliesTo.endDate).diff(moment(p.startDate), "days")+1:p.totalWorkDays
               const basePerDay = basedOn === "serviceHours"?p.dailyServiceHour:basedOn === "daysWorked"?1:p[basedOn] / p.daysWorked
               return  sum+ (daysWorked<p.totalWorkDays?basePerDay * daysWorked:basePerDay * p.totalWorkDays)

        },0)
        setName(newName)

        setTotalTime(totalTimeCalculated)

        props.onChange({category,type, name: newName,baseAmount:baseAmount,appliesTo:app,basedOn, taxOption, applied, totalTime:totalTimeCalculated})
    }

    const setEndDate = endDate => {

      //  console.log({startDate, calendar: props.payrollRun.calendar})
        let endOfMonth = endDate
        if(!!endDate)
        {
            const selectedDate = moment(endDate.startOf('month')).add(15, 'days')
            const ecDate =   Zemen.toEC(selectedDate.get('year'),selectedDate.get('month'),selectedDate.get('date'))
            const totalDaysInPayPeriod = props.payrollRun.periodDaySetting? props.payrollRun.periodDaySetting: endDate.daysInMonth();
            // console.log({totalDaysInPayPeriod})
            endOfMonth = props.payrollRun.calendar==='EC'?Zemen.toGC(ecDate.getFullYear(),ecDate.getMonth(),30):endDate.startOf('month').add(totalDaysInPayPeriod-1,'days').set('h',23).set('minute',59).toDate()
        }
      // const normalizedEndOfMonth = moment(endOfMonth).add(-1,"day").set('h',23).set('minute',59)
       // periodEnd = moment(startOfMonth).add(totalDaysInPayPeriod-1,'days').set('h',23).set('minute',59)

        // console.log(props.payrollRun.payrolls.filter(p=>!p.employee))

        const payrolls  = props.payrollRun.payrolls.filter(p=>!p.excludeFromDistEarnings&&(!appliesTo.groups.length||appliesTo.groups.map(g=>g.toLowerCase()).includes(p.siteName.toLowerCase()))&&(!endDate||moment(p.startDate)<=moment(endOfMonth)))
        const app = {
            endDate:endOfMonth,
            groups:appliesTo.groups,
            emps:payrolls.map(p=>p.employeeId)}
        setAppliesTo(
            app
        )

        const totalTimeCalculated  = payrolls.reduce((sum,p)=>{

                const daysWorked = sum + p.distEarningsOnActualDate ? moment(endOfMonth).diff(moment(p.startDate), "days") + 1 : p.totalWorkDays
                const basePerDay = basedOn === "serviceHours" ? p.dailyServiceHour : basedOn === "daysWorked" ? 1 : p[basedOn] / p.daysWorked

                return sum + (daysWorked < p.totalWorkDays ? basePerDay * daysWorked : basePerDay * p.totalWorkDays)

        },0)

        const newName = `*${type} ${appliesTo.groups.join(",")} ${endOfMonth?moment(endOfMonth).add(-15,"days").format("MMM"):''}`
        setName( newName)
        setTotalTime(totalTimeCalculated)
        props.onChange({category,type, name: newName,baseAmount:baseAmount,appliesTo:app,basedOn, taxOption, applied, totalTimeCalculated})
    }

    const refreshAppliedTo = () => {



        const app = {
            groups:appliesTo.groups,
            endDate: appliesTo.endDate,
            emps:props.payrollRun.payrolls.filter(p=>!p.excludeFromDistEarnings&&(!appliesTo.groups.length||appliesTo.groups.map(g=>g.toLowerCase()).includes(p.siteName.toLowerCase()))&&(!appliesTo.endDate||moment(p.startDate)<=moment(appliesTo.endDate))).
            map(p=>p.employeeId)}
        setAppliesTo(
            app
        )

       return {category,type,  name,baseAmount,appliesTo:app,basedOn, taxOption, applied:true,totalTime}
    }
    return (
            <Grid container>
            {/*category*/}
            <Grid item xs={1}>
                <FormControl margin='dense' fullWidth  >
                    <InputLabel>Category</InputLabel>
                    <Select
                        value={category}
                        onChange={ev=> {
                            setCategory(ev.target.value)
                            if (ev.target.value === 'earnings') {
                                setType('serviceCharge')
                                setName('*Service Charge')
                            }
                            else if (ev.target.value === 'deductions') {
                                setType('deduction')
                                setName('Deduction')
                            }
                            else if (ev.target.value === 'expenses') {
                                setType('healthInsurance')
                                setName('Health Insurance')
                            }
                            else {
                                setType('')
                                setName('')
                            }
                            props.onChange({category:ev.target.value,type, name,baseAmount,appliesTo, basedOn, taxOption, applied, totalTime})
                           
                        }}
                    >
                        {categories.map(to=><MenuItem key={to.id} value={to.id}>{to.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
           
           {category==='earnings'&&<Grid item xs={1}>
                <FormControl margin='dense' fullWidth  >
                    <InputLabel>Earning Type</InputLabel>
                    <Select
                        value={type}
                        onChange={ev=> {
                            const selectedType = earningTypes.find(t=>t.id===ev.target.value)
                            setType(ev.target.value)
                            setName(selectedType.name)
                            setTaxOption(selectedType.taxOption)

                            props.onChange({category,type: ev.target.value, name:selectedType.name, taxOption:selectedType.taxOption,baseAmount, basedOn,appliesTo, applied, totalTime})                        }}
                    >
                        {earningTypes.map(to=><MenuItem key={to.id} value={to.id}>{to.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>}
            <Grid item xs={2}>


                <TextField
                    onChange={ev => {
                        setName(ev.target.value)
                        props.onChange({category,type, name: ev.target.value,baseAmount,appliesTo, basedOn, taxOption, applied, totalTime})

                    }}
                    value={name}
                    margin="dense"
                    label="Title"
                    fullWidth
                />
            </Grid>

            {category==='earnings'&&<Grid item xs={1}>
                <FormControl margin='dense' fullWidth  >
                    <InputLabel>Tax Option</InputLabel>
                    <Select
                        value={taxOption}
                        onChange={ev=> {
                            setTaxOption(ev.target.value)
                            props.onChange({category,type, name,taxOption:ev.target.value,baseAmount,appliesTo, basedOn, applied, totalTime})
                        }}
                    >
                        {[
                            { id: 'NonTaxable', name: 'NonTaxable' },
                            { id: 'Taxable', name: 'Taxable' },
                            { id: 'TaxableWithLimits', name: 'TaxableWithLimits' },
                            { id: 'TaxableWithPayback', name: 'Taxable But Payback' },
                        ].map(to=><MenuItem key={to.id} value={to.id}>{to.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>}


            <Grid item xs={2}>
                <TextField
                    onChange={ev => {
                        setBaseAmount(+ev.target.value)
                        props.onChange({category,type, name,baseAmount:+ev.target.value,appliesTo,basedOn,taxOption, applied,totalTime})
                    }}
                    value={baseAmount}
                    type="number"
                    autoFocus
                    margin="dense"
                    label="Base Amount"
                    fullWidth
                />

            </Grid>
            <Grid item xs={2}>
                <FormControl margin='dense' fullWidth  >
                    <InputLabel>Based On</InputLabel>
                    <Select
                        value={basedOn}
                        onChange={ev=> {
                            setBasedOn(ev.target.value)
                            props.onChange({category,type, name,baseAmount,appliesTo,basedOn:ev.target.value,taxOption, applied,totalTime})
                        }}
                    >
                        {[
                            { name: 'Equal Distribution', id: 'equalDistribution' },
                            {name:'Exact Distribution',id:'exactDistribution'},
                            { name: 'Actual Hours', id: 'actualHoursWorked' },
                            { name: 'Payable Hours', id: 'hoursWorked' },
                            { name: 'Days Worked', id: 'daysWorked' },
                            { name: 'Expected Hours', id: 'monthlyHours' },
                            { name: 'Service Hours', id: 'serviceHours' },
                        ].map(i=><MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>)}
                    </Select>
                </FormControl>

            </Grid>

            {appliesTo.endDate&&<Grid item xs={1}>
                <TextField
                    onChange={ev => {
                        setTotalTime(+ev.target.value)
                        props.onChange({
                            type,
                            name,
                            baseAmount,
                            appliesTo,
                            basedOn,
                            taxOption,
                            applied,
                            totalTime: +ev.target.value
                        })
                    }}
                    value={totalTime}
                    type="number"
                    margin="dense"
                    label={`Total Time`}
                    fullWidth
                />

            </Grid>}

            <Grid item xs={1}>
                      <span> Applies to   {appliesTo.emps.length} Employees </span> {appliesTo.endDate&&<span> hired before {moment(appliesTo.endDate).format("MMM Do, YYYY")} </span>} <br/>
                in {appliesTo.groups.join(',')}

                <DistEarningsAppliesToPopover setEndDate={setEndDate} setGroups={setGroups} groups={props.groups} appliesTo={appliesTo} payrollRun={props.payrollRun}/>


            </Grid>


            <Grid item xs={1}>
                <IconButton onClick={props.onRemove} aria-label="delete" >
                    <DeleteIcon fontSize="large" />
                </IconButton>

                <IconButton color="success" onClick={async ()=>{

                    props.onChange(refreshAppliedTo())
                    setApplied(true)
                    props.onApply(refreshAppliedTo())
                }} aria-label="apply" >
                    {applied?<RefreshOutlined fontSize="large"/>:<PlayArrow fontSize="large"/>}
                </IconButton>


            </Grid>

        </Grid>
    );
};

export default DistEarningItem;
