import { useDispatch } from "react-redux";
import { ImportButton } from "../import/importButton";
import React from 'react';

const ImportPayrollUpdates = ({ payrolls }) => {
    // const dataProvider = useDataProvider();
    const dispatch = useDispatch()
    // console.log({payrolls})

    const lookUp = {}
    payrolls.forEach(p=>{
        if(p.fullName) {
            lookUp[p.fullName.replace(/\s+/g, '').toLowerCase()] = {employeeId:p.employeeId,orgID:p.orgID}
        }

    })

    // console.log({lookUp})


    const onImport = (res,values) => {
        // console.log({res, values})
        // const updates = []
        values.forEach(v=>{
            const emp  = lookUp[v.fullName.replace(/\s+/g, '').toLowerCase()]
          if(emp) {
              Object.keys(v).forEach(k=>{
                  //   const rate = k.split('.')[1]
                  if (k.toLowerCase()!=='fullname') {
                      const value = v[k]
                      console.log({value, k, emp})

                      //   updates.push({ ...emp, category: 'update', type: k, name: k, value: +value })
                      dispatch({ type: 'PAYROLL_ITEM_CHANGED', payload: { event: { dataItem: emp, field: k, value: +value } } })
            
                  }
              })
          }

        })

        // console.log({earnings})

        // dispatch({type:"PAYROLL_EARNINGS_DEDUCTIONS_UPDATED",payload: {items:earnings, idField:"employeeId", deductionTypes, earningTypes}})

        // const earnings = _.groupBy(values,'fullName')
        // console.log(earnings)

        // dataProvider
        //     .getList('employees')
        //     .then(response => {
        //         if(response.data.length)
        //         {
        //             // console.log({values})
        //             const tss = values.map(ts=>{
        //
        //                 const e = response.data.find(e=>e.fullname.replace(/\s+/g, '').toLowerCase() === ts.fullName.replace(/\s+/g, '').toLowerCase());
        //                 if(e&&+ts.value!==0)
        //                 {
        //                     const extraData = {}
        //                     Object.keys(ts).forEach(key=>{
        //                         if(key.startsWith("extra")&&key!=='extra') {
        //                             // ts.address.push(key);
        //                             const name = key.split('.')[1]?key.split('.')[1]:""
        //                             // console.log(name,"earnings")
        //
        //                             extraData[name]= (+ts[key])
        //
        //                         }
        //                     })
        //
        //                     return {employeeId:e.id, orgID:e.orgID,category:ts.category||ts.kind||'deduction',cash:ts.cash, type:ts.type, name:ts.name, value:ts.value, taxOption:ts.taxOption, extraData }
        //                 }
        //             }).filter(t=>t)
        //
        //             // console.log(tss)
        //
        //             // console.log(response.data[0].payrolls); // { id: 123, title: "hello, world" }
        //             // onPayrollDeductionEarnings( tss );
        //             dispatch({type:"PAYROLL_EARNINGS_DEDUCTIONS_UPDATED",payload: {items:tss, idField:"employeeId", deductionTypes, earningTypes}})
        //
        //         }
        //
        //     });

    }
    return <ImportButton key={"updateImport"} label='Update Payroll from File' onImport={onImport}/>
}

export default ImportPayrollUpdates