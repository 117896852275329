import React, {useRef} from 'react';
import ExcelExport from "@progress/kendo-react-excel-export/dist/npm/ExcelExport";
import ExcelExportColumn from "@progress/kendo-react-excel-export/dist/es/ExcelExportColumn";
import Button from "@material-ui/core/Button";
import {ExcelIcon} from "../../UIComponents/icons";
import {green} from "@material-ui/core/colors";
import moment from "moment";
import {getImageDimensions} from "../../Helpers/b64toBiteArray";
const decimalFormat= ['0','0.0','0.00','0.000','0.0000']
const BankListExcelExport = ({data,fileName,payrollDate,logo,decimalPlace=2,footerItems,showFooter,columns}) => {
    const getSum = ({column}) => {
        // console.log(field)
        return data.reduce((sum,cv)=>sum+cv[column.field],0).toFixed(2)
    }
    const loadLogosAndExportToExcel = () => {

  
        const url = logo; //"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
        if(url) {
            fetch(url)
                .then(res => res.arrayBuffer())
                // .then(x=>console.log(JSON.stringify(x)))
                .then(data => getImageDimensions(url).then(dim=>exportToExcel({logo: {type: "image/png", data,dim}})))
        }
        else {
            exportToExcel();
        }
    }


    const ref = useRef();
    const exportToExcel = (images) => {
        const options = ref.current.workbookOptions();
        const sheet = options.sheets[0];
        if(images){
            options.images = images;
            sheet.drawings = [
                {
                    topLeftCell: "A1",
                    offsetX: 12,
                    offsetY: 3,
                    width: (images["logo"].dim.w/images["logo"].dim.h)*25,
                    height: 25,
                    image: "logo"
                },
            ]
        }
        sheet.frozenRows = 3;
       // sheet.mergedCells = ["A1:F1","A2:F2"];
        const cols = columns.filter(c=>c.show).length;
        sheet.mergedCells = [`A1:${String.fromCharCode(64 + cols-1)}1`,`A2:${String.fromCharCode(64 + cols-1)}2`];

        /// sheet.mergedCells = ["A2:G2"];
        sheet.name = "Bank Transfer List";

        var company = [{
            value:localStorage.getItem('companyName'),
            fontSize: 20,
            textAlign: "center",
            background:"#098EF6",
            color:"#ffffff"
        },{
            value:'',
            verticalAlign:"bottom",
            fontSize: 15,
            textAlign: "right",
            background:"#098EF6",
            color:"#ffffff",
            bold:true
        }];

        var sheetTitle = [{
            value:`Bank Transfer List   - ${moment(payrollDate).format("MMMM YYYY")} Payroll`,
            verticalAlign:"top",
            fontSize: 20,
            textAlign: "center",
            background:"#098EF6",
            color:"#ffffff"
        },
            {
                value:'Payday.truwrk.com',
                verticalAlign:"bottom",
                fontSize: 10,
                textAlign: "right",
                background:"#098EF6",
                color:"#ffffff",
                bold:true
            }];

        const rows = sheet.rows;

        let altIdx = 0;
        rows.forEach((row) => {
            if(row.type === 'header')
            {
                row.height = 50
                row.cells.forEach((cell) => {
                    cell.borderLeft={size:1}
                    cell.borderRight={size:1}
                    cell.borderBottom={size:1}
                    cell.borderTop={size:1}
                    cell.fontSize = 18
                    cell.background="#BAD8FC"
                    cell.color = "#000"
                    cell.wrap = true
                    cell.bold = true


                });

            }
            if(row.type === 'footer')
            {
                row.height = 30
                row.cells.forEach((cell) => {
                    cell.borderLeft={size:1}
                    cell.borderRight={size:1}
                    cell.borderBottom={size:1}
                    cell.borderTop={size:1}
                    cell.fontSize = 22
                    cell.background="#E5E7E7"
                    cell.color = "#000"
                    cell.bold = true


                });

            }


            if (row.type === 'data') {
                // if (altIdx % 2 !== 0) {
                //     row.cells.forEach((cell) => {
                //         cell.background = '#aabbcc';
                //     });
                // }
                // altIdx++;
                row.height = 35
                    row.cells.forEach((cell) => {
                        cell.borderLeft={size:1}
                        cell.borderRight={size:1}
                        cell.borderBottom={size:1}
                        cell.borderTop={size:1}
                        cell.fontSize = 22

                    });
            }
        });

        sheet.rows.splice(0, 0, { cells: sheetTitle, height: 40});
        sheet.rows.splice(0, 0, { cells: company, height: 40});

        let footerCells = [[]];
        footerItems.forEach(f=>{
            footerCells = [ ...footerCells,
                {
                    value:`${f.field}:`,
                    fontSize: 18,
                    textAlign: "right",
                    bold:true,
                    wrap:true,
                },
                {
                    value:`_${f.value?f.value:'_______'}_`,
                    fontSize: 18,
                    wrap:true,
                    underline:true,
                    textAlign: "left",
                    // bold:true,
                },]
        })
        sheet.rows.push({cells:[], height:70})
        if(showFooter) {
            sheet.rows.push({cells: cols>4?footerCells:footerCells.filter(f=>!!f.value), height: 50})
        }



      ref.current.save(options);
    }
    // const defaultHeaderOptions = {wrap:true,fontSize:18, background:"#09a3f6",  borderLeft:{size:1}, borderRight:{size:1}, borderBottom:{size:1},borderTop:{size:1}}

    //const defaultFooterOption= {wrap:true,fontSize:18, background:"#f69709",  borderLeft:{size:1}, borderRight:{size:1}, borderBottom:{size:1},borderTop:{size:1}}

    return (
        <div>

            <Button variant="outlined"  color="primary"  startIcon={<ExcelIcon  style={{ color: green[500] }}/>} onClick={loadLogosAndExportToExcel}>Export to Excel</Button>

            <ExcelExport
                data={data}
                ref={ref}
                fileName={fileName}
            >
                {
                    columns.map((c,index)=> c.show&& <ExcelExportColumn width={c.width} field={c.field} title={c.title}  cellOptions={c.type==="Number"&&{format: `_(* #,##${decimalFormat[decimalPlace]}_);_(* (#,##${decimalFormat[decimalPlace]});_(* "-"??_);_(@_)`}} footer={c.type==="Number"?getSum:index===columns.length-2?val=>'Total':null} footerCellOptions={c.type==="Number"&&{format: `_(* #,##${decimalFormat[decimalPlace]}_);_(* (#,##${decimalFormat[decimalPlace]});_(* "-"??_);_(@_)`}} />)
                }

                {/*<ExcelExportColumn width={50} field="orgID" title="Work Id" />*/}
                {/*<ExcelExportColumn width={150} field="fullName" title="Employee Name"  />*/}
                {/*<ExcelExportColumn width={100} field="bank" title="Bank" />*/}
                {/*<ExcelExportColumn width={100} field="bankBranch" title="Bank Branch" />*/}
                {/*<ExcelExportColumn width={150} field="bankAccountNumber" title="Account" footer={val=>'Total'}  />*/}
                {/*<ExcelExportColumn width={150} field="cash" title="Net Transfer Amount"  cellOptions={{format: `_(* #,##${decimalFormat[decimalPlace]}_);_(* (#,##${decimalFormat[decimalPlace]});_(* "-"??_);_(@_)`}} footer={getSum} footerCellOptions={{format: `_(* #,##${decimalFormat[decimalPlace]}_);_(* (#,##${decimalFormat[decimalPlace]});_(* "-"??_);_(@_)`}}/>*/}

            </ExcelExport>

        </div>
    );
};

export default BankListExcelExport;
