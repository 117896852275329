import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchEnd, fetchStart, refreshView, showNotification } from 'react-admin';
import OdataServiceProvider from "../Helpers/odataServiceProvider";

export default function* employeeAdjustmentSaga() {
    yield takeLatest('APPROVE_ADJUSTMENT', function* (action) {
        const { payload } = action;
        try {
            yield put(fetchStart());

            yield call(OdataServiceProvider.serverAction, 'employeeAdjustments', {
                action: 'apply',
                id: payload.id
            });

            yield put(fetchEnd());
            yield put(showNotification('Adjustment approved', 'info'));
            yield put(refreshView());

        } catch (e) {
            const message = e.body && e.body.value;
            yield put(showNotification(`Adjustment approval failed: ${message}`, 'error'));
            yield put(fetchEnd());
        }
    });
} 