import React from 'react';
import {
    AutocompleteInput,
    Button,
    Create,
    Datagrid,
    DateField,
    Edit,
    List,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    Show,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    ArrayInput,
    CreateButton,
    SimpleFormIterator,
    TextInput, useQueryWithStore, useRefresh,FormDataConsumer
} from 'react-admin';
import Typography from "@material-ui/core/Typography";
import exporterCommon from "../import/exporterCommon";
import MyDatagrid from "../accountsGrid";
import MultiCurrencyField from "../../UIComponents/multiCurrencyField";
import DefaultListActions, {Empty} from "./common";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/AddToQueue";
import ImportButton from "../import/importButton";
import {connect, useSelector, useStore} from "react-redux";
import {parse} from "query-string";
import Grid from "@material-ui/core/Grid";
import {useForm} from "react-final-form";
import {ReferenceField} from "react-admin";


export const ResetAccountsButton = connect(null,dispatch=>({
    resetAccounts: ()=>dispatch({type:'RESET_DEFAULT_ACCOUNTS'})
}))(props=> {

    return (
        <Button onClick={props.resetAccounts} label="Reset Default Accounts"/>
    );
});



export const EmptyAccounts = ({ basePath, resource }) => (
    <Box textAlign="center" m={1}>
        <AddIcon color="secondary" style={{ fontSize: 200 }}/>
        <Typography color="textSecondary" variant="h4" paragraph>
            No {resource} yet
        </Typography>
        <Typography color="textSecondary" variant="subtitle1">
            Reset defaults accounts or import from a file
        </Typography>
        <ResetAccountsButton/>
        <ImportButton resource={resource} basePath={basePath} />
        <CreateButton basePath={basePath}/>
    </Box>
);


export const AccountList= props => (
    <List  perPage={100}  empty={<EmptyAccounts />}   exporter={exporterCommon(props.resource)}  actions={<DefaultListActions/>} {...props}>
        <MyDatagrid checkField={true} summeryFields={['debit','credit','balance']} rowClick="show">
            <TextField source="code" />
            <ReferenceField source="costCenterId" reference="costCenters" >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="tags" />
           
     
            {/* <MultiCurrencyField  source="debit" />
            <MultiCurrencyField source="credit" />
            <MultiCurrencyField label='Balance (Debit)' source="balance" /> */}
        </MyDatagrid>
    </List>
);

const Aside = ({record}) => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="h6">Account details</Typography>
        <Typography variant="body2">
          {record &&record.name}
        </Typography>
    </div>
);


export const AccountShow = props => (
    <Show aside={<Aside/>} {...props}>
        <TabbedShowLayout>
            <Tab label="Basic Info">
        <TextField source="id" />
        <TextField source="name" />
            </Tab>
            <Tab label="Transactions" path="transactions">
                <ReferenceManyField
                    addLabel={false}
                    reference="transactionRecords"
                    target="accounts"
                    filter={{expand:`transaction`}}

                >
                    <Datagrid>
                        <DateField source="date" />
                        <TextField label="Description" source="transaction.description" />
                        <MultiCurrencyField source="debit" />
                        <MultiCurrencyField source="credit" />

                     </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const AccountEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <CreateAccountForm {...props}/>
        </SimpleForm>
    </Edit>
);

export const AccountCreate = props => {
    const { employeeId } =parse(props.location.search);

    const redirect = employeeId ? `/employees/${employeeId}/show/accounts` : "list";



    return   <Create {...props}>
            <SimpleForm redirect={redirect} initialValues={{employeeId:+employeeId}} >
                <CreateAccountForm employeeId={employeeId}/>


            </SimpleForm>
        </Create>

};

const CreateAccountForm = props =>{
    const form = useForm();
    // console.log(form.getFieldState('category'))
    const store = useStore();



   return <FormDataConsumer>
       {({ formData, ...rest }) => {

           function onTemplateChange(ev) {
               // console.log({ev,cat:formData.category})
               const template = store.getState().admin.resources[formData.category === 'loans' ? 'loanTemplates' : formData.category]['data'][ev.target.value];
               const employee = store.getState().admin.resources['employees']['data'][formData.employeeId];
               // console.log({employee})
               form.change('name', (employee && employee.fullname) + '-' + (template && template.name))
               form.change('templateType', template && template.name)
           }


           return <>
        
        
               <ReferenceInput label="AccountType" source="typeId" reference="accountTypes" validate={[required()]}>
                   <SelectInput optionText="name" />
               </ReferenceInput>
               <TextInput source="code" validate={[required()]} />
               <TextInput source="name" />
               <TextInput source="description" />
            <ArrayInput source="tags">
          <SimpleFormIterator inline>
            <TextInput />
          </SimpleFormIterator>
        </ArrayInput>
           

               <ReferenceInput label="Parent Account" source="parrentAccountId" reference="accounts" >
                   <AutocompleteInput options={{ type: 'search' }} optionText={(record) => record.code + "--" + record.name} />
               </ReferenceInput>

               <ReferenceInput label="Cost Center" source="costCenterId" reference="costCenters" >
                   <AutocompleteInput options={{ type: 'search' }} optionText={(record) => record.code + "--" + record.name} />
               </ReferenceInput>
               <Grid container fullWidth>
                   <Grid item>
                       <ReferenceInput perPage={1000} label="Employee" source="employeeId" reference="employees" filter={{ filter: "active eq true" }}>
                           <AutocompleteInput options={{ disabled: !!props.employeeId }} optionText="fullname" />
                       </ReferenceInput>
                   </Grid>
                   {formData.employeeId?
                       <>
                           <Grid item>

                               <SelectInput source="category" choices={[
                                   { id: 'loans', name: 'Loan' },
                                   { id: 'deductions', name: 'Deduction' },
                                   { id: 'earnings', name: 'Earning' },
                                   { id: 'expenses', name: 'Expense' },
                               ]} />
                           </Grid>

                           {formData.category && <Grid item>
                               <ReferenceInput onChange={onTemplateChange} label="Template" source="templateId"
                                   reference={formData.category === 'loans' ? 'loanTemplates' : formData.category}
                                   filter={{ filter: `Scope eq 'Template'` }}
                               >
                                   <SelectInput optionText="name" />
                               </ReferenceInput>
                           </Grid>
                           }
     
                           <Grid item>
                               <TextInput source="templateType" />
                           </Grid>
                       </>:null
                   }


               </Grid>
           </>
       }}
           </FormDataConsumer>
}
