import React from 'react';
import { useSelector } from 'react-redux';
import { LinearProgress, Typography, Box } from '@material-ui/core';

const ImportProgress = () => {
    const { isImporting, currentOperation, progress, total } = useSelector(
        state => state.importProgress
    );

    if (!isImporting) return null;

    const percentage = Math.round((progress / total) * 100);

    return (
        <Box position="fixed" bottom={0} left={0} right={0} p={2} bgcolor="background.paper" boxShadow={3}>
            <Typography variant="body2" color="textSecondary">
                {currentOperation}
            </Typography>
            <LinearProgress 
                variant="determinate" 
                value={percentage} 
                style={{ marginTop: 8, marginBottom: 4 }}
            />
            <Typography variant="body2" color="textSecondary" align="right">
                {`${percentage}% (${progress}/${total})`}
            </Typography>
        </Box>
    );
};

export default ImportProgress; 