import React, { Fragment } from 'react';
import {
    Create,
    Datagrid,
    Edit,
    FormDataConsumer,
    List,
    NumberInput,
    ReferenceInput,
    ReferenceField,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    AutocompleteInput,
} from 'react-admin';
import { RedirectToolbar } from "./common";
import DefaultListActions, { Empty } from "./common";
import exporterCommon from "../import/exporterCommon";
import Grid from "@material-ui/core/Grid";
import { parse } from "query-string";
import PercentField from '../../UIComponents/PercentField';
export const CostCenterAllocationList = props => (
    <List 
        empty={<Empty />} 
        exporter={exporterCommon(props.resource)} 
        actions={<DefaultListActions/>} 
        {...props}
    >
        <Datagrid rowClick="edit">
             <ReferenceField label="Employee" source="employeeId" reference="employees">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Cost Center" source="costCenterId" reference="costCenters">
                <TextField source="name" />
            </ReferenceField>
            <PercentField source="allocationPercentage" />
        </Datagrid>
    </List>
);

export const CostCenterAllocationEdit = props => {
    //    / const {redirect, scopeText, scope} = getScope(props, "costCenterAllocations")
    const { employeeId } = parse(props.location.search);
    const redirect = employeeId ? `/employees/${employeeId}/show/employeeCostCenterAllocations` : "list";

    // console.log(redirect, "redirect")

    return <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<RedirectToolbar/>}>
            <FormDataConsumer>
                {formDataProps => (
                    <CostCenterAllocationForm {...formDataProps} employeeId={employeeId} />
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
};

const CostCenterAllocationForm = ({ formData, employeeId, ...rest }) => {
    return <Fragment>
        <Grid container spacing={2} style={{width:"100%"}}>
            <Grid item xs={12}>
                <ReferenceInput
                    {...rest}
                    source="employeeId"
                    reference="employees"
                    validate={required()}
                    disabled={!!employeeId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </Grid>

            <Grid item xs={12}>
                <ReferenceInput 
                    perPage={1000} 
                    source="costCenterId" 
                    reference="costCenters"
                    validate={required()}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
            </Grid>

            <Grid item xs={12}>
                <NumberInput 
                    source="allocationPercentage"
                    validate={required()}
                    format={v => v ? v * 100 : v}
                    parse={v => v ? v / 100 : v}
                    min={0}
                    max={100}
                />
            </Grid>

            <Grid item xs={12}>
                <TextInput 
                    source="remarks" 
                    multiline
                    rows={4}
                    fullWidth
                />
            </Grid>
        </Grid>
    </Fragment>
};

export const CostCenterAllocationCreate = props => {
    const { employeeId } = parse(props.location.search);
    const redirect = employeeId ? `/employees/${employeeId}/show/employeeCostCenterAllocations` : "list";
    
    return (
        <Create {...props}>
            <SimpleForm 
                initialValues={{
                    employeeId: employeeId,
                }} 
                redirect={redirect}
            >
                <FormDataConsumer>
                    {formDataProps => (
                        <CostCenterAllocationForm {...formDataProps} employeeId={employeeId} />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
}; 