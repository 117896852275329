import React, { useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Modal from "@material-ui/core/Modal";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, FormControlLabel, Switch } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
        maxWidth: '90%',
        maxHeight: '90vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        outline: 'none',
    },
    noteCard: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        backgroundColor: '#f5f5f5',
        position: 'relative'
    },
    deleteButton: {
        position: 'absolute',
        top: 8,
        right: 8,
        padding: 4
    },
    formControl: {
        minWidth: 200,
        marginBottom: theme.spacing(2)
    }
}));

const NotesPopover = props => {
    const classes = useStyles();
    const [notes, setNotes] = useState(props.notes || []);
    const [open, setOpen] = useState(false);
    const [newNote, setNewNote] = useState('');
    const [selectedField, setSelectedField] = useState('');
    const [saveToEmployee, setSaveToEmployee] = useState(true);

    const payrollFields = [
        'basicSalary',
        'basicSalaryAdjustment',
        'transportAllowance', 
        'net',
        'deductions',
        'overtimeTotal',
        'bonusesTotal',
        'totalIncome',
        'netPay',
        'cash'
    ];

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddNote = () => {
        if (!newNote.trim()) return;

        const note = {
            note: newNote,
            noteField: selectedField,
            noteType: 'comment',
            noteBy: props.currentUser || 'System',
            noteDate: new Date(),
            employeeId: saveToEmployee?props.employeeId:null,
            payrollDate: moment(props.payrollDate).format('YYYY-MM-DD')
        };

        const updatedNotes = [...notes, note];
        setNotes(updatedNotes);
        props.onChange(updatedNotes);
        setNewNote('');
        setSelectedField('');
        setSaveToEmployee(true);
        handleClose();
    };

    const handleDeleteNote = (index) => {
        const updatedNotes = notes.filter((_, i) => i !== index);
        setNotes(updatedNotes);
        props.onChange(updatedNotes);
    };

    return (
        <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block' }}>
                 <span style={{fontSize:15,fontWeight:'bold'}}>Notes ({notes.length})</span> 
                <IconButton color="primary" onClick={handleOpen}>
                    <CommentIcon />
                </IconButton>
            </div>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                aria-labelledby="notes-modal"
                aria-describedby="notes-description"
            >
                <Card className={classes.card}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {notes.map((note, index) => (
                                    <Card key={index} className={classes.noteCard}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                {note.note}
                                                {note.noteBy === props.currentUser && (
                                                    <IconButton 
                                                        className={classes.deleteButton}
                                                        size="small"
                                                        onClick={() => handleDeleteNote(index)}
                                                    >
                                                        <DeleteIcon fontSize="small" color="error" />
                                                    </IconButton>
                                                )}
                                              
                                            </Grid>
                                            <Grid item xs={12}>
                                                <small>
                                                    {note.noteField && `Field: ${note.noteField} | `}
                                                    By: {note.noteBy} | 
                                                    Date: {moment(note.noteDate).format('DD/MM/YYYY HH:mm')}
                                                </small>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>Related Field</InputLabel>
                                    <Select
                                        value={selectedField}
                                        onChange={(e) => setSelectedField(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {payrollFields.map(field => (
                                            <MenuItem key={field} value={field}>
                                                {field}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControlLabel
                                                    control={
                                        <Switch
                                            color="primary"
                                                            checked={saveToEmployee}
                                                            onChange={(e) => setSaveToEmployee(e.target.checked)}
                                                            size="small"
                                                        />
                                                    }
                                                    label="Saved to Employee"
                                                    style={{marginLeft: 8}}
                                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                    label="New Note"
                                    value={newNote}
                                    onChange={(e) => setNewNote(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} style={{display:'flex',justifyContent:'space-between',gap:10}}>
                            <Button 
                                    variant="contained" 
                                    color="secondary"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    onClick={handleAddNote}
                                >
                                    Add Note
                                </Button>
                           
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Modal>
        </div>
    );
};

export default NotesPopover; 