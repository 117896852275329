import React, {Fragment} from 'react';
import {
    BooleanInput,
    CheckboxGroupInput,
    Create,
    Datagrid,
    Edit,
    FormDataConsumer,
    List, minLength,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useQueryWithStore
} from 'react-admin';
import {useForm} from "react-final-form";
import exporterCommon from "../import/exporterCommon";
import {RedirectToolbar} from "./common";
import {getScope, scopeResourcesEnum} from "./helperFunctions";
import {DateInput} from "react-admin-date-inputs2";

export const ExpenseList= props => (
    <List  exporter={exporterCommon(props.resource)} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
        </Datagrid>
    </List>
);


export const ExpenseEdit = props => {
    const {redirect,scopeText,scopeId,scopeName,scope} = getScope(props,"expenses")

    return <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<RedirectToolbar/>}>
            <FormDataConsumer>
                {formDataProps => (
                    <ExpenseForm {...formDataProps} scopeText={scopeText} scope={scope} />
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
};


const choices=[
    { id: 'CompanyPension', name: 'Pension (Company)' },
    { id: 'CompanyPF', name: 'Provident Fund(Company)' },
    { id: 'Health Insurance', name: 'Health Insurance' },
    { id: 'Other', name: 'Other' },

]
const ExpenseForm  = ({ formData, scopeText,scope, ...rest }) => {

    const form = useForm();
    const {data: templates} = useQueryWithStore({
        type: 'getList',
        resource: 'expenses',
        payload: {filter:{filter:`Scope eq 'Template'`},pagination:{},sort:{}}
    });
    return <Fragment>
        <ReferenceInput {...rest}
                        source={`${scopeText.toLowerCase()}Id`}
                        reference={scopeResourcesEnum[+scope]}
                        validate={required()}
                        disabled
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput  onChange={event=>{
            const item = templates.find(e=>e.id===event.target.value)
            form.change('name',item.name)
            form.change('type',item.type)
            form.change('valueType',item.valueType)
            form.change('dayBased',item.dayBased)
            form.change('basedOn',item.basedOn)
            form.change('currency',item.currency)
            // form.change('templateId',item.id)
        }}   {...rest} label="Template" source="templateId"  choices={templates}  />


        <SelectInput {...rest} source="type" onChange={value=>form.change('name',choices.find(c => c.id===value.target.value).name)}  choices={choices}  />
        <TextInput {...rest} source="name" validate={[required(),minLength(2)]}  />


        <SelectInput {...rest} source="valueType"  choices={[
            { id: 'Absolute', name: 'Absolute' },
            { id: 'Percentage', name: 'Percentage' },
        ]}  />

        {/*<NumberInput {...rest} source="value" />*/}
        {+scope!==3&&<NumberInput format={v => formData.valueType === "Percentage" ? (v * 1000000) / 10000 : v}
                      parse={v => formData.valueType === "Percentage" ? v / 100 : v}  {...rest} source="value"/>
        }
        {+scope<=1&&<>
            <CheckboxGroupInput   source="appliesTo" choices = {[
                { id: 'FullTime', name: 'FullTime' },
                { id: 'Contract', name: 'Contract' },
                { id: 'PartTime', name: 'PartTime' },
                { id: 'InternOrFellow', name: 'InternOrFellow' },
                { id: 'Casual', name: 'Casual' },
        ]} />

            <CheckboxGroupInput   source="appliesToCitizens" choices = {[
                { id: "Ethiopian", name: 'Ethiopians' },
                { id: "Non-Ethiopian", name: 'Non Ethiopians' },
              ]} />
        </>}

        <BooleanInput source="dayBased" />
        {+scope!==3&& <DateInput     source={"startDate"} label="Start date" options={{ format: 'DD/MM/YYYY', inputVariant:'filled', clearable:true  }}  />}
        {+scope===3&& <BooleanInput  source="showOriginal" />}
    </Fragment>
}

export const ExpenseCreate = props =>
{
    const {redirect,scopeText,scopeId,scopeName,scope} = getScope(props,"expenses")
    //console.log(scopeText)
    return    (

        <Create {...props}>
            <SimpleForm  initialValues={{appliesTo:['FullTime'],appliesToCitizens:['Ethiopian','Non-Ethiopian'],[`${scopeText.toLowerCase()}Id`]:scopeId,scope:scopeName,dayBased:true}} redirect={redirect}>

                <FormDataConsumer>
                    {formDataProps => (
                        <ExpenseForm {...formDataProps} scopeText={scopeText} scope={scope} />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )};
