import {all, call, put, takeLatest} from 'redux-saga/effects';
import {fetchEnd, fetchStart, refreshView, showNotification} from 'react-admin';
import OdataServiceProvider from "../Helpers/odataServiceProvider";
import _ from "lodash";
import moment from "moment";
// import Alert from "@material-ui/lab/Alert";
function confirmDialog(msg) {
    return new Promise(function (resolve, reject) {
        let confirmed = window.confirm(msg);

        return confirmed ? resolve(true) : reject(false);
    });
}

export default function* importSaga() {
    yield takeLatest('IMPORT_DATA', function* (action) {
        const {payload} = action;
        let existingData;
        
        try {
            yield put(fetchStart());
            const {data:list} = yield call(OdataServiceProvider.getList, payload.resource);
            
            // Start progress tracking
            yield put({ 
                type: 'IMPORT_PROGRESS_START', 
                payload: { 
                    total: payload.data.length,
                    operation: 'Preparing import...' 
                } 
            });

            // console.log(payload)
            yield put(fetchStart());
            try {
               // console.log(payload)
                if(payload.resource==='loans')
                {
                    const {data: employees }= yield call(OdataServiceProvider.getList,"employees",{filter:{filter:'active eq true'}})
                  //  console.log(employees)
                    payload.data = payload.data.filter(e=>!!e.requestedAmount).map(e=>{
                        if(e.orgID)
                        {
                          //  console.log(e)

                            const emp = employees.find(p=>p.orgID===e.orgID)
                            if(emp)
                            {e.employeeId = emp.id;}
                        }

                        return _.omit(e,['orgID'])
                    }


                    )
                   // console.log(payload.data);

                }
                if(payload.resource==='positions')
                {



                    const {data: departments }= yield call(OdataServiceProvider.getList,"departments")
                    const {data: jobLevels }= yield call(OdataServiceProvider.getList,"jobLevels")

                    // console.log(payload.data)
                    payload.data = payload.data.map(e=>{
                        if(e.department)
                        {
                            const dep = departments.find(p=>p.name.toLowerCase().replace(/\s+/g, '')===e.department.toLowerCase().replace(/\s+/g, ''))
                            if(dep)
                            {e.departmentID = dep.id;}
                        }

                            if(e.jobLevel)
                            {
                                const level = jobLevels.find(p=>p.name.toLowerCase().replace(/\s+/g, '')===e.jobLevel.toLowerCase().replace(/\s+/g, ''))
                                if(level)
                                {e.jobLevelId = level.id}
                            }

                     return _.omit(e,['department','jobLevel'])
                    }



                    )

                    existingData  = payload.data.filter(
                        p=> {
                            const po = list.find(l => (l.id===p.id) || l.name.toLowerCase().replace(/\s+/g, '')===(p.name.toLowerCase().replace(/\s+/g, '')))
                            if(po){
                                p.id = po.id
                                return true
                            }
                            else return false
                        })


                   // console.log(payload.data)
                }

                if(payload.resource==='employees')
                {
                    //const exsistingNames = list.map(i=>(i.name+i.fatherName +i.gFatherName).toLowerCase().replace(/\s+/g, ''));


                    // const existingEmployees  = payload.data.filter(
                    //     e=>exsistingNames
                    //         .includes((e.name+e.fatherName+e.gFatherName).toLowerCase().replace(/\s+/g, ''))
                    // ).map(e=>{
                    //     const emp = list.find(l=>(e.name+e.fatherName+e.gFatherName).toLowerCase().replace(/\s+/g, ''))
                    // })

                   // console.log("list",list)




                    const {data }= yield call(OdataServiceProvider.getOne,"companies",{ id: localStorage.getItem('companyId'), expand:`earnings($filter = Scope eq 'Company'),deductions($filter = Scope eq 'Company'),expenses($filter = Scope eq 'Company')`})
                    //console.log(data)
                    const er = data.earnings.map(e => {
                        const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                        f.parentScope = f.scope
                        f.scope="Employee"
                        f.copyRefId= e.id
                        return f
                    }  )

                    const d = data.deductions.map(e => {
                        const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                        f.parentScope = f.scope
                        f.scope="Employee"
                        f.copyRefId= e.id
                        return f
                    }  )
                    // console.log(d,data)


                  //  const filteredDeductions = d.filter(d => d.)


                    const ex = data.expenses.map(e => {
                        const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                        f.parentScope = f.scope
                        f.scope="Employee"
                        f.copyRefId= e.id
                        return f
                    }  )



                    payload.data.forEach(
                        e=> {
                            const name = ((e.title||'')+(e.name||'')+(e.fatherName||'')+(e.gFatherName||'')).toLowerCase().replace(/\s+/g, '')

                            //todo too costy
                            const emp = list.find(l=>
                                (e.id&&e.id===l.id)||
                                ( name
                                    ===
                                    l.fullname.toLowerCase().replace(/\s+/g, '')) ||
                                (l.orgID === e.orgID && !e.name && !e.fatherName)||

                                (l.orgID === e.orgID && l.name.toLowerCase().replace(/\s+/g, '')===e.name.toLowerCase().replace(/\s+/g, '')) ||
                                (l.orgID === e.orgID && l.fatherName.toLowerCase().replace(/\s+/g, '')===e.fatherName.toLowerCase().replace(/\s+/g, ''))


                            )
                           
                            Object.keys(e).forEach(key => {
                                if (e[key] === '-') {
                                    e[key] = '';
                                }
                            });


                            if(emp) {
                                e.id = emp.id;
                                return  true
                            } else {
                                return  false
                            }

                        })


                   payload.data.filter(e=>!e.id).map(e=>{


                       e.employmentType = e.employmentType?e.employmentType.replace(/\s+/g, ''):"FullTime"
                     //  console.log(e.citizenship )
                       e.citizenship = e.citizenship || "Ethiopian"
                       e.startDate = !e.startDate&&e.employmentType==="Contract"?moment():e.startDate
                       e.contractEndDate= !e.contractEndDate&&(e.employmentType==="Contract"||e.employmentType==="Casual")?moment().add(2,"months"):e.contractEndDate
                     //  console.log(e.citizenship )

                       e.earnings = er.filter(i=>i.appliesTo.map(a=>a.toLowerCase()).includes(e.employmentType.toLowerCase())&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase().replace(/\s+/g, '')).includes(e.citizenship.toLowerCase().replace(/\s+/g, '')))) ;
                       e.deductions =  d.filter(i=>i.appliesTo.map(a=>a.toLowerCase()).includes(e.employmentType.toLowerCase())&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase().replace(/\s+/g, '')).includes(e.citizenship.toLowerCase().replace(/\s+/g, '')))&&(!i.deductionType||i.deductionType.toLowerCase()!=="emppension"||!e.pensionNo||e.pensionNo!='-1')) // d.filter(i=>i.deductionType.toLowerCase()!=="emppension" || ((!e.citizenship || e.citizenship.toLowerCase().replace(/\s+/g, '') === 'ethiopian' || e.citizenship.toLowerCase().replace(/\s+/g, '') === '')&&e.employmentType!=="PartTime" ));           // skip non ethiopians and parttimeers on penstions
                       e.expenses = ex.filter(i=>i.appliesTo.map(a=>a.toLowerCase()).includes(e.employmentType.toLowerCase())&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase().replace(/\s+/g, '')).includes(e.citizenship.toLowerCase().replace(/\s+/g, '')))&&(!i.type||i.type.toLowerCase()!=="companypension"||!e.pensionNo||e.pensionNo!='-1'))
// console.log("ded",e.deductions)

                       // console.log(d, d.filter(i=>i.appliesTo.includes('FullTime')), e.employmentType)

                           //ex.filter(i=>i.type.toLowerCase()!=="companypension" ||
                       //     (
                       //         (
                       //             !e.citizenship || e.citizenship.toLowerCase().replace(/\s+/g, '') === 'ethiopian' || e.citizenship.toLowerCase().replace(/\s+/g, '') === '' )&&e.employmentType!=="PartTime" ));                // skip non ethiopians on penstions
                       //
                       e.loans = []

                     //  'positions', { id: positionId, expand:'earnings,deductions,expenses'}

                   })



                   // const {data: departments }= yield call(OdataServiceProvider.getList,"departments")
                    // console.log(payload.data)
                    // payload.data = payload.data.map (e=>{
                    //         if(e.department)
                    //         {
                    //             const dep = departments.find(p=>p.name.toLowerCase()===e.department.toLowerCase())
                    //             if(dep)
                    //             {e.departmentID = dep.id;}
                    //         }
                    //
                    //         return _.omit(e,['department'])
                    //     }
                    //
                    // )



                    const {data: positions }= yield call(OdataServiceProvider.getList,"positions",{filter:{expand:`earnings,deductions,expenses`}})
                    const {data: departments }= yield call(OdataServiceProvider.getList,"departments")
                    const {data: costCenters }= yield call(OdataServiceProvider.getList,"costCenters")
                    const {data: sites }= yield call(OdataServiceProvider.getList,"sites")
                    const {data: templateEarnings }= yield call(OdataServiceProvider.getList,"earnings",{filter:{filter:`scope eq 'Template'`} })
                    const {data: templateDeductions }= yield call(OdataServiceProvider.getList,"deductions",{filter:{filter:`scope eq 'Template'`} })
                    const {data: templateExpenses }= yield call(OdataServiceProvider.getList,"expenses",{filter:{filter:`scope eq 'Template'`} })
                    const {data: banks }= yield call(OdataServiceProvider.getList,"companyBanks")
                  // console.log("payload",payload)
                    payload.data = payload.data.map (e=>{
                       if(e.position)
                       {
                           const pos = positions.find(p=>p.name.toLowerCase().replace(/\s+/g, '')===e.position.toLowerCase().replace(/\s+/g, ''))

                           if(pos)
                           {
                               e.positionId = pos.id;
                               // pos.earnings = pos.earnings.map(pe=>_.omit(pe, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"]));
                               // pos.deductions= pos.deductions.map(pe=>_.omit(pe, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"]));
                               // pos.expenses = pos.expenses.map(pe=>_.omit(pe, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"]));


                               const er = pos.earnings.filter(i=>i.appliesTo.map(a=>a.toLowerCase()).includes(e.employmentType.toLowerCase())&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, '')))).map(i=> {
                                   // consoli.log(e)
                                   const f = _.omit(i, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                                   f.parentScope = f.scope
                                   f.scope="Employee"
                                   f.copyRefId= i.id
                                   //  f.earningType = i.earningType
                                   return f
                               }  )

                               const d = pos.deductions.filter(i=>i.appliesTo.map(a=>a.toLowerCase()).includes(e.employmentType.toLowerCase())&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, '')))).map(i=> {
                                   const f = _.omit(i, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                                   f.parentScope = f.scope
                                   f.scope="Employee"
                                   f.copyRefId= i.id
                                   return f
                               }  )

                               const ex = pos.expenses.filter(i=>i.appliesTo.map(a=>a.toLowerCase()).includes(e.employmentType.toLowerCase())&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, '')))).map(i=> {
                                   const f = _.omit(i, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                                   f.parentScope = f.scope
                                   f.scope="Employee"
                                   f.copyRefId= i.id
                                   return f
                               }  )



                               e.earnings =    e.earnings||[].concat(er) //pos.earnings.filter(i=>i.appliesTo.includes(e.employmentType)&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, ''))))) ;
                               e.deductions =  e.deductions||[].concat(d) // pos.deductions.filter(i=>i.appliesTo.includes(e.employmentType)&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, ''))))) // d.filter(i=>i.deductionType.toLowerCase()!=="emppension" || ((!e.citizenship || e.citizenship.toLowerCase().replace(/\s+/g, '') === 'ethiopian' || e.citizenship.toLowerCase().replace(/\s+/g, '') === '')&&e.employmentType!=="PartTime" ));           // skip non ethiopians and parttimeers on penstions
                               e.expenses =   e.expenses||[].concat(ex)//pos.expenses.filter(i=>i.appliesTo.includes(e.employmentType)&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, '')))));
                               // console.log(er,e)
                           }



                       }
                        if(e.site)
                        {
                            const site = sites.find(p=>p.name.toLowerCase().replace(/\s+/g, '')===e.site.toLowerCase().replace(/\s+/g, ''))
                            if(site)
                            {e.siteId = site.id;}
                        }
                        if(e.department)
                        {
                            const department = departments.find(p=>p.name.toLowerCase().replace(/\s+/g, '')===e.department.toLowerCase().replace(/\s+/g, ''))
                            if(department)
                            {e.departmentId = department.id;}
                        }

                        if(e.costCenter)
                        {
                            if(e.costCenter==-1) {
                                e.costCenterId = null
                            }
                            else {
                                const costCenter = costCenters.find(p=>(p.name.toLowerCase().replace(/\s+/g, '')===e.costCenter.toLowerCase().replace(/\s+/g, ''))||(p.code.toLowerCase().replace(/\s+/g, '')===e.costCenter.toLowerCase().replace(/\s+/g, '')))
                                if(costCenter)
                                {e.costCenterId = costCenter.id;}
                            }

                        }
                       if(e.bank)
                       {
                           const bank = banks.find(p=>p.bank.name.toLowerCase().replace(/\s+/g, '')===e.bank.toLowerCase().replace(/\s+/g, ''))
                           if(bank)
                           {e.companyBankId = bank.id;}
                       }

                       const keys = {earning:[],loan:[], deductions:[], expenses:[], address:[]}
                        Object.keys(e).map(key=>{
                            //console.log(key)
                            if(key.startsWith("earnings")&&key!=='earnings') {
                                keys.earning.push(key);
                                const name = key.split('.')[1]?key.split('.')[1]:""
                               // console.log(name,"earnings")
                                const template = templateEarnings.find(e=>e.name.toLowerCase().replace(/\s+/g, '')===name.toLowerCase())
                               // console.log(template,"template")
                                if(template) {
                                    const x =  _.pick(template, ['name', 'taxable','taxOption','limitByValue','valueType','dayBased', 'basedOn', 'currency'])
                                    //console.log(e.earnings)
                                 
                                 if(!x.valueType&&e[key]) {
                                        if (+e[key] < 1) {
                                            x.valueType = 'Percentage'
                                        } else {
                                            x.valueType = 'Absolute'
                                        }
                                    }
                                  x.value = x.basedOn?0:e[key]
                                    e.earnings = [...e.earnings||[],{...x,templateId:template.id,earningType:template.name}]
                                  // if(!e.earnings){
                                  //     e.earnings = []
                                  // }
                                  //   e.earnings.push(
                                  //       {...x,value:e[key]}
                                  //   )

                                }

                                    }
                         //   console.log(key.split('.').length)

                            if(key.startsWith("deductions")&&key!=='deductions') {
                                keys.deductions.push(key);
                                const name = key.split('.')[1]?key.split('.')[1]:""
                                // console.log(name,"deduction")
                                const template = templateDeductions.find(e=>e.name.toLowerCase().replace(/\s+/g, '')===name.toLowerCase())
                                 //console.log(template,"decutons")
                                if(template) {
                                    const x =  _.pick(template, ['name', 'valueType', 'deductionType', 'cash','dayBased' ])
                                    //console.log(e.earnings)
                                    if(e[key]) {
                                        if (+e[key] <= 1) {
                                            x.valueType = 'Percentage'
                                        } else {
                                            x.valueType = 'Absolute'
                                        }
                                    }
                                    e.deductions = [...e.deductions||[],{...x,value:e[key],templateId:template.id}]
                                    // if(!e.earnings){
                                    //     e.earnings = []
                                    // }
                                    //   e.earnings.push(
                                    //       {...x,value:e[key]}
                                    //   )

                                }

                            }

                            if(key.startsWith("loans")&&key!=='loans'&&key.split('.').length<3) {
                                keys.loan.push(key);
                                keys.loan.push(key+'.installment');
                                keys.loan.push(key+'.repaid');
                                const name = key.split('.')[1]?key.split('.')[1]:""
                                e.loans= [...e.loans||[],{type:"Loan",name:_.startCase(name),requestedAmount:e[key],installmentAmount:e[key+'.installment'],repayedAmount:e[key+'.repaid']}]
                            }

                            if(key.startsWith("expenses")&&key!=='expenses') {
                                keys.expenses.push(key);
                                const name = key.split('.')[1]?key.split('.')[1]:""
                                // console.log(name,"earnings")
                                const template = templateExpenses.find(e=>e.name.toLowerCase().replace(/\s+/g, '')===name.toLowerCase())
                                // console.log(template,"template")
                                if(template) {
                                    const x =  _.pick(template, ['name', 'valueType', 'type' ,'cash','dayBased' ])

                                  if(e[key]) {
                                      if (+e[key] <= 1) {
                                          x.valueType = 'Percentage'
                                      } else {
                                          x.valueType = 'Absolute'
                                      }
                                  }


                                    e.expenses = [...e.expenses||[],{...x,value:e[key],templateId:template.id}]
                                    // if(!e.earnings){
                                    //     e.earnings = []
                                    // }
                                    //   e.earnings.push(
                                    //       {...x,value:e[key]}
                                    //   )

                                }

                            }


                            if(key.startsWith("address")&&key!=='address') {
                                keys.address.push(key);
                                const name = key.split('.')[1]?key.split('.')[1]:""
                                // console.log(name,"earnings")
                                if(!e.address) e.address = {};
                                e.address[name]=e[key]

                                }




                        })

                     //  console.log(keys)



//console.log(keys)
                        const res = _.omit(e,['bank','dateFormat','calendar','department','costCenter','position','site',...keys.earning,...keys.loan, ...keys.expenses, ...keys.deductions,...keys.address])

                        return res;//_.omitBy(res, _.isNil);

                    })

                   /// console.log(payload.data)

                    existingData  = payload.data.filter(e=>e.id);
                 //   payload.data = _.omit(payload.data,['position'])
                   /// console.log(payload.data,"data")

                    // to-to temporary solution untill patch with nested collection is fixed
                  //  existingData = existingData.map(e=>_.omit(e,["deductions", "earnings",  "expenses", "loans"]))






                }

                if (payload.resource === 'accounts')
                {
                    //accountTypes
                    const { data: accountTypes } = yield call(OdataServiceProvider.getList, "accountTypes")
                    const {data: accounts} = yield call(OdataServiceProvider.getList, "accounts")
                    //costCenters
                    const { data: costCenters } = yield call(OdataServiceProvider.getList, "costCenters")
                    payload.data = payload.data.map(e=>{
                        const accountType = accountTypes.find(p=>p.name.toLowerCase().replace(/\s+/g, '')===(e.type&&e.type.toLowerCase().replace(/\s+/g, '')))
                        if(accountType)
                        {
                            e.typeId = accountType.id
                        }
                        const costCenter = costCenters.find(p=>p.code.toLowerCase().replace(/\s+/g, '')===(e.costCenter&&e.costCenter.toLowerCase().replace(/\s+/g, '')))
                        console.log({costCenters:costCenters.map(p=>p.code.toLowerCase().replace(/\s+/g, '')), costCenter})
                        if(costCenter)
                        {
                            e.costCenterId = costCenter.id
                        }
                        const parentAccount = accounts.find(p=>p.code.toLowerCase().replace(/\s+/g, '')===(e.parrentAccount&&e.parrentAccount.toLowerCase().replace(/\s+/g, '')))
                        if(parentAccount)
                        {
                            e.parrentAccountId = parentAccount.id
                        }


                        const tags = e.tags&&e.tags.split('-').map(t=>t.trim())
                        if(tags)
                        {
                            e.tags = tags
                        }

                        return _.omit(e,['type','costCenter', 'parrentAccount'])
                    })
                    // console.log(payload.data)
                }

                //sites
                if(payload.resource==='sites')
                {
                    //regions
                    const { data: regions } = yield call(OdataServiceProvider.getList, "regions")
                    payload.data = payload.data.map(e => {
                     
                        const region = regions.find(p=>p.name.toLowerCase().replace(/\s+/g, '')===e.region.toLowerCase().replace(/\s+/g, ''))
                        if(region)
                        {
                            e.regionId = region.id
                        }
                        return _.omit(e,['region'])
                    })
                }

                if(payload.resource==='employeeCostCenterAllocations') {
                    const { data: employees } = yield call(OdataServiceProvider.getList, "employees")
                    const { data: costCenters } = yield call(OdataServiceProvider.getList, "costCenters")

                    // Check if data is in horizontal format by looking for cost center codes in column headers
                    const isHorizontalFormat = Object.keys(payload.data[0]).some(key => 
                        costCenters.some(cc => cc.code.toLowerCase().replace(/\s+/g, '') === key.toLowerCase().replace(/\s+/g, ''))
                    );

                    if (isHorizontalFormat) {
                        // Transform horizontal format to vertical
                        const transformedData = [];
                        
                        payload.data.forEach(row => {
                            // Find employee first
                            const name = ((row.title||'')+(row.name||'')+(row.fatherName||'')+(row.gFatherName||'')).toLowerCase().replace(/\s+/g, '');
                            const employee = employees.find(l =>
                                (row.id && row.id === l.id) ||
                                (name === l.fullname.toLowerCase().replace(/\s+/g, '')) ||
                                (l.orgID === row.orgID && !row.name && !row.fatherName) ||
                                (l.orgID === row.orgID && row.name && l.name.toLowerCase().replace(/\s+/g, '') === row.name.toLowerCase().replace(/\s+/g, '')) ||
                                (l.orgID === row.orgID && row.fatherName && l.fatherName.toLowerCase().replace(/\s+/g, '') === row.fatherName.toLowerCase().replace(/\s+/g, ''))
                            );

                            if (employee) {
                                // For each cost center column in the row
                                Object.entries(row).forEach(([key, value]) => {
                                    const costCenter = costCenters.find(cc => 
                                        cc.code.toLowerCase().replace(/\s+/g, '') === key.toLowerCase().replace(/\s+/g, '')
                                    );

                                    if (costCenter && value) {
                                        transformedData.push({
                                            employeeId: employee.id,
                                            costCenterId: costCenter.id,
                                            allocationPercentage: parseFloat(value)
                                        });
                                    }
                                });
                            }
                        });

                        payload.data = transformedData;
                    } else {
                        // Handle vertical format (existing logic)
                        payload.data = payload.data.map(e => {
                            const name = ((e.title||'')+(e.name||'')+(e.fatherName||'')+(e.gFatherName||'')).toLowerCase().replace(/\s+/g, '');
                            const employee = employees.find(l =>
                                (e.id && e.id === l.id) ||
                                (name === l.fullname.toLowerCase().replace(/\s+/g, '')) ||
                                (l.orgID === e.orgID && !e.name && !e.fatherName) ||
                                (l.orgID === e.orgID && e.name && l.name.toLowerCase().replace(/\s+/g, '') === e.name.toLowerCase().replace(/\s+/g, '')) ||
                                (l.orgID === e.orgID && e.fatherName && l.fatherName.toLowerCase().replace(/\s+/g, '') === e.fatherName.toLowerCase().replace(/\s+/g, ''))
                            );

                            if(employee) {
                                e.employeeId = employee.id;
                            }

                            const costCenter = costCenters.find(p => 
                                e.costCenter && p.code.toLowerCase().replace(/\s+/g, '') === e.costCenter.toLowerCase().replace(/\s+/g, '')
                            );
                            
                            if(costCenter) {
                                e.costCenterId = costCenter.id;
                            }

                            return _.omit(e, ['employee', 'costCenter', 'name', 'fatherName', 'gFatherName', 'title', 'orgID']);
                        });
                    }
                }

                if(payload.resource === 'employees') {
                    yield put({ 
                        type: 'IMPORT_PROGRESS_UPDATE',
                        payload: { 
                            progress: 0,
                            operation: 'Processing employee data...'
                        }
                    });
                    
                    // Process employees with progress updates
                    for(let i = 0; i < payload.data.length; i++) {
                        const e = payload.data[i];
                        // ... existing employee processing code ...
                        
                        yield put({
                            type: 'IMPORT_PROGRESS_UPDATE',
                            payload: {
                                progress: i + 1,
                                operation: `Processing employee ${i + 1}/${payload.data.length}`
                            }
                        });
                    }
                }

                // Handle new items creation
                const newItems = payload.data.filter(e => !e.id);
                if(newItems.length) {
                    yield put({
                        type: 'IMPORT_PROGRESS_UPDATE',
                        payload: {
                            progress: 0,
                            operation: `Creating ${newItems.length} new items...`
                        }
                    });
                    
                    const res = yield call(OdataServiceProvider.createMany, payload.resource, {
                        data: newItems,
                        onProgress: (completed, total) => {
                            put({
                                type: 'IMPORT_PROGRESS_UPDATE',
                                payload: {
                                    progress: completed,
                                    operation: `Creating items (${completed}/${total})...`
                                }
                            });
                        }
                    });
                    
                    yield put(showNotification(`${newItems.length} New Items Imported`, "info"));
                }

                // Handle existing items update
                if(existingData && existingData.length > 0) {
                    try {
                        yield call(confirmDialog, `${existingData.length} Items already exist, do you want to overwrite?`);
                        
                        yield put({
                            type: 'IMPORT_PROGRESS_UPDATE',
                            payload: {
                                operation: `Updating ${existingData.length} existing items...`
                            }
                        });

                        yield all(
                            existingData.map(data => {
                                return call(OdataServiceProvider.update, payload.resource, {
                                    data: _.omit(data, ["deductions", "earnings", "expenses", "loans"]), 
                                    id: data.id
                                });
                            })
                        );

                        yield put(showNotification(`${existingData.length} Items Updated`, "info"));
                    } catch(e) {
                        yield put({ type: 'IMPORT_PROGRESS_END' });
                        yield put(fetchEnd());
                    }
                }

                yield put({ type: 'IMPORT_PROGRESS_END' });
                yield put(fetchEnd());
                yield put(fetchEnd());
                yield put(refreshView());
            } catch (e) {
                console.log(e);
                yield put(showNotification(`Sorry, importing failed please recheck your data`, "error"));
                yield put({ type: 'IMPORT_PROGRESS_END' });
                yield put(fetchEnd());
                yield put(refreshView());
            }
        } catch (e) {
            console.log(e);
            yield put(showNotification(`Sorry, importing failed please recheck your data`, "error"));
            yield put({ type: 'IMPORT_PROGRESS_END' });
            yield put(fetchEnd());
            yield put(refreshView());
        }
    });
}
