import React, { Fragment, useEffect } from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    FormDataConsumer,
    List,
    ReferenceInput,
    ReferenceField,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    DateInput,
    NumberInput,

    useQueryWithStore,
    AutocompleteInput,
    useDataProvider,
    useRefresh,
    NumberField,
    useNotify
} from 'react-admin';
import { useForm } from 'react-final-form';
import { RedirectToolbar } from "./common";
import DefaultListActions, { Empty } from "./common";
import exporterCommon from "../import/exporterCommon";
import Grid from "@material-ui/core/Grid";
import { parse } from "query-string";
import moment from 'moment';
import { Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { EditButton } from 'react-admin';
import { useDispatch } from 'react-redux';

const adjustmentTypeChoices = [
    { id: 'Salary', name: 'Salary' },
    { id: 'Site', name: 'Site' },
    { id: 'Position', name: 'Position' },
    { id: 'Earnings', name: 'Earnings' },
    { id: 'Deductions', name: 'Deductions' },
    { id: 'Expenses', name: 'Expenses' },
    { id: 'Other', name: 'Other' },
];

const adjustmentStatusChoices = [
    { id: 'Pending', name: 'Pending' },
    { id: 'Applied', name: 'Applied' },
    { id: 'Dismissed', name: 'Dismissed' },
];

export const ApproveButton = ({ record }) => {
    const dispatch = useDispatch();
    const notify = useNotify();

    const handleClick = () => {
        dispatch({
            type: 'APPROVE_ADJUSTMENT',
            payload: { id: record.id }
        });
    };

    return record && record.status === 'Pending' ? (
        <Button
            onClick={handleClick}
        >
            <CheckIcon />
            Approve
        </Button>
    ) : null;
};

const PendingEditButton = ({ record, ...rest }) => 
    record && record.status === 'Pending' ? <EditButton record={record} {...rest} /> : null;

export const AdjustmentValueField = ({ record, source, ...rest }) => {
    if (!record || !record.adjustmentType) return null;
    console.log("record", record, source);

    switch (record.adjustmentType) {
        case 'Site':
            return (
                <ReferenceField 
                    record={record} 
                    source={source} 
                    reference="sites"
                    {...rest}
                >
                    <TextField source="name" />
                </ReferenceField>
            );
        case 'Position':
            return (
                <ReferenceField 
                    record={record} 
                    source={source} 
                    reference="positions"
                    {...rest}
                >
                    <TextField source="name" />
                </ReferenceField>
            );
        case 'Earnings':
            return record.refId && source === 'value' ? (
                <ReferenceField 
                    record={record} 
                    source="refId"   
                    reference="earnings"
                    {...rest}
                >
                    <TextField source="name" />
                </ReferenceField>
            ) : (
                <TextField source={source} />
            );
        case 'Deductions':
            return record.refId && source === 'value' ? (
                <ReferenceField 
                    record={record} 
                    source="refId" 
                    reference="deductions"
                    {...rest}
                >
                    <TextField source="name" />
                </ReferenceField>
            ) : (
                <TextField source={source} />
            );
        case 'Expenses':
            return record.refId && source === 'value' ? (
                <ReferenceField 
                    record={record} 
                    source="refId" 
                    reference="expenses"
                    {...rest}
                >
                    <TextField source="name" />
                </ReferenceField>
            ) : (
                <TextField source={source} />
            );
        default:
            return <NumberField source={source} record={record} {...rest} />;
    }
};

export const EmployeeAdjustmentList = props => (
    <List 
        empty={<Empty />} 
        exporter={exporterCommon(props.resource)} 
        actions={<DefaultListActions/>} 
        {...props}
    >
        <Datagrid rowClick="edit">
            <ReferenceField label="Employee" source="employeeId" reference="employees">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="effectiveDate" />
            <TextField source="adjustmentType" />
            <TextField source="name" />
            <AdjustmentValueField source="value" />
            <AdjustmentValueField source="oldValue" />
            <TextField source="status" />
            <TextField source="approvedBy" />
            <DateField source="approvedDate" />
            <ApproveButton />
            <PendingEditButton />
        </Datagrid>
    </List>
);

export const EmployeeAdjustmentEdit = props => {
    const { employeeId } = parse(props.location.search);
    const redirect = employeeId ? `/employees/${employeeId}/show/employeeAdjustments` : "list";

    return <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<RedirectToolbar/>}>
            <FormDataConsumer>
                {formDataProps => (
                    <EmployeeAdjustmentForm {...formDataProps} employeeId={employeeId} />
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
};

const ValueInput = ({ adjustmentType, employee, source, disabled = false, ...rest }) => {
    const form = useForm();
    const dataProvider = useDataProvider();
    
    if (!adjustmentType) return null;
    if (!employee) return null;

    const handleReferenceChange = (event) => {
        // console.log("event", event);
        const refId = event
        if (!refId) return;

        // Load the reference item's value based on type
        const resource = adjustmentType.toLowerCase();
        
        dataProvider.getOne(resource, { id: refId })
            .then(({ data }) => {
                console.log("data", data);
                if (data) {
                    form.change('value', data);
                    form.change('oldValue', data);
                }
            });
    };

    switch (adjustmentType) {
        case 'Salary':
            return (
                <NumberInput 
                    source={source}
                    initialValue={employee && employee.basicSalary}
                    disabled={disabled}
                    validate={!disabled && required()}
                    {...rest}
                />
            );
        case 'Site':
            return (
                <ReferenceInput
                    source={source}
                    reference="sites"
                    initialValue={employee && employee.siteId}
                    disabled={disabled}
                    validate={!disabled && required()}
                    {...rest}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
            );
        case 'Position':
            return (
                <ReferenceInput
                    source={source}
                    reference="positions"
                    initialValue={employee && employee.positionId}
                    disabled={disabled}
                    validate={!disabled && required()}
                    {...rest}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
            );
        case 'Earnings':
        case 'Deductions':
        case 'Expenses':
            return (
                <>
                  { source === 'value' && <ReferenceInput
                        source="refId"
                        reference={adjustmentType.toLowerCase()}
                        filter={{ filter: `employeeId eq ${employee && employee.id}` }}
                        validate={!disabled && required()}
                        onChange={handleReferenceChange}
                        {...rest}
                    >
                        <AutocompleteInput optionText="name" />
                    </ReferenceInput>}
                    <NumberInput 
                        source={source}
                        disabled={disabled}
                        validate={!disabled && required()}
                        {...rest}
                    />
                </>
            );
        
        default:
            return (
                <TextInput 
                    source={source}
                    disabled={disabled}
                    validate={!disabled && required()}
                    {...rest}
                />
            );
    }
};

const EmployeeAdjustmentForm = ({ formData, employeeId, ...rest }) => {
    const form = useForm();
    
    const { data: employee } = useQueryWithStore({
        type: 'getOne',
        resource: 'employees',
        payload: { id: employeeId || (formData && formData.employeeId) }
    });

    useEffect(() => {
        if (employee && formData && formData.adjustmentType) {
            // Set oldValue based on adjustment type
            switch (formData.adjustmentType) {
                case 'Salary':
                    form.change('oldValue', employee.basicSalary && employee.basicSalary.toString());
                    form.change('currency', employee.basicCurrency || 'ETB');
                    break;
                case 'Site':
                    form.change('oldValue', employee.siteId && employee.siteId.toString());
                    break;
                case 'Position':
                    form.change('oldValue', employee.positionId && employee.positionId.toString());
                    break;
            }
        }
    }, [employee, formData && formData.adjustmentType]);

    useEffect(() => {
        if (formData && formData.adjustmentType) {
            // Auto-generate name
            const monthYear = moment().format('MMM');
            form.change('name', `${monthYear}-${formData.adjustmentType}`);
        }
    }, [formData && formData.adjustmentType]);

    return (
        <Fragment>
            <Grid container spacing={2} style={{width:"100%"}}>
                <Grid item xs={12}>
                    <ReferenceInput
                        {...rest}
                        source="employeeId"
                        reference="employees"
                        validate={required()}
                        disabled={!!employeeId}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Grid>

                <Grid item xs={12}>
                    <DateInput 
                        source="effectiveDate"
                        validate={required()}
                        options={{ format: 'DD/MM/YYYY' }}
                    />
                    <SelectInput 
                        source="adjustmentType"
                        choices={adjustmentTypeChoices}
                        validate={required()}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextInput 
                        source="name"
                        validate={required()}
                        disabled
                    />
                    <TextInput 
                        source="description" 
                        multiline
                        rows={3}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormDataConsumer>
                        {({ formData }) => (
                            <>
                                <ValueInput 
                                    adjustmentType={formData && formData.adjustmentType}
                                    employee={employee}
                                    source="value"
                                />
                                <ValueInput 
                                    adjustmentType={formData && formData.adjustmentType}
                                    employee={employee}
                                    source="oldValue"
                                    disabled
                                />
                                {formData && formData.adjustmentType === 'Salary' && (
                                    <SelectInput 
                                        source="currency"
                                        choices={[
                                            { id: 'ETB', name: 'ETB' },
                                            { id: 'USD', name: 'USD' },
                                            { id: 'EUR', name: 'EUR' },
                                            { id: 'GBP', name: 'GBP' },
                                        ]}
                                        disabled
                                    />
                                )}
                            </>
                        )}
                    </FormDataConsumer>
                </Grid>

                <Grid item xs={12}>
                    <TextInput 
                        source="remarks" 
                        multiline
                        rows={3}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectInput 
                        source="status"
                        choices={adjustmentStatusChoices}
                        validate={required()}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export const EmployeeAdjustmentCreate = props => {
    const { employeeId } = parse(props.location.search);
    const redirect = employeeId ? `/employees/${employeeId}/show/employeeAdjustments` : "list";
    
    return (
        <Create {...props}>
            <SimpleForm 
                initialValues={{
                    employeeId: employeeId,
                    status: 'Pending',
                    effectiveDate: new Date(),
                }} 
                redirect={redirect}
            >
                <FormDataConsumer>
                    {formDataProps => (
                        <EmployeeAdjustmentForm {...formDataProps} employeeId={employeeId} />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
}; 